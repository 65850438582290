import moment from 'moment';
import _ from 'lodash';
import uniqid from 'uniqid';
import Cookies from 'js-cookie';
import { getContrastColor, readInputFile } from '../utils/utils';

const axios = require('axios');
const { publicRuntimeConfig } = require('next/config').default();
const { salsaURL, sseSource, webAppURL } = require('../constants').get();
const { webAppURL: prodWebAppURL } = require('../constants').get('prod');

class BackendSalsa {
  constructor() {
    this.URL = salsaURL;
  }

  getEvent({ eventId, token, subdomain }) {
    const query = `query {
                eventBasic(id: "${eventId}", passwordAccessToken: "${token}") {
                    id,
                    eventId,
                    title,
                    subtitle,
                    eventDate,
                    fullGalleryEnabled,
                    fontColor,
                    passwordProtected,
                    backgroundColor,
                    backgroundUrl,
                    logoUrl,
                    logoLink,
                    publicOwnerEmail,
                    buttonFlag,
                    buttonText,
                    buttonLink,
                    contactEnabled
                    error,
                    errorCode,
                    micrositeAccentColor,
                    analyticsToken,
                    organization {
                        plan,
                        licenseCount
                    },
                    liveGallery {
                        theme,
                        termsLink,
                        privacyLink,
                        leadSection,
                        companySection,
                        company {
                            headline,
                            email,
                            phone,
                            website,
                            facebook,
                            twitter,
                            instagram
                        },
                        socialMediaSection,
                        socialMediaCaptions {
                            facebook,
                            facebook_enabled,
                            twitter,
                            twitter_enabled,
                            reddit,
                            reddit_enabled,
                            tumblr,
                            tumblr_enabled,
                            pinterest,
                            pinterest_enabled,
                            default
                        }
                    },
                    analytics {
                        totalPhotos
                    },
                    captureConfig {
                      modeConfiguration {
                        videoConfiguration {
                          audioEnabled
                        }
                      }
                    },
                    virtualBoothConfig {
                      backgroundUrl,
                      buttonUrl
                    }
                }
            }`;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getEventAnalytics({ analyticsToken, eventId }) {
    return this._salsaRestApi(`/events/${eventId}/analytics/${analyticsToken}?level=3`, { method: 'GET' });
  }

  getSurveyResults({ eventId, token, subdomain }) {
    const query = `query{
                surveyResults(
                    id: "${eventId}",
                    analyticsToken: "1234"
                ) {
                    rows {
                        question,
                        answers {
                            value,
                            count
                        }
                    }
                }
            }`;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getMediasWithFilters({ eventId, eventMedias, subdomain, filters }) {
    const query = `query {
            photos: photos(eventId: "${eventId}", pagination: {count: 12}, filters: ${filters}) {
                mediaId,
                eventId,
                mediaUrl,
                thumbnailUrl,
                videoThumbnailUrl,
                type,
                dateCreated,
                captureMode
            }
        }`;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getMedias({ eventId, eventMedias, subdomain, dateFrom = null }) {
    let minDate = dateFrom;
    let maxDate = null;
    let query = '';
    const dates = _.map(eventMedias, 'date');
    if (dates && dates.length) {
      maxDate = `"${_.max(dates)}"`;
      minDate = `"${_.min(dates)}"`;
      query = `query {
                photosBefore: photos(eventId: "${eventId}", pagination: {count: 12, before: ${minDate} }) {
                    mediaId,
                    eventId,
                    mediaUrl,
                    thumbnailUrl,
                    videoThumbnailUrl,
                    type,
                    dateCreated,
                    captureMode
                }

                photosAfter: photos(eventId: "${eventId}", pagination: {count: 12, after: ${maxDate} }) {
                    mediaId,
                    eventId,
                    mediaUrl,
                    thumbnailUrl,
                    type,
                    dateCreated,
                    captureMode
                }
            }`;
    } else {
      query = `query {
                photosBefore: photos(eventId: "${eventId}", pagination: {count: 12, before: ${minDate} }) {
                    mediaId,
                    eventId,
                    mediaUrl,
                    thumbnailUrl,
                    videoThumbnailUrl,
                    type,
                    dateCreated,
                    captureMode
                }
            }`;
    }
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getMediasByIds({ eventId, mediaIds, subdomain }) {
    const query = `query {
            photos(
                eventId: "${eventId}",
                mediaIds: ${JSON.stringify(mediaIds)}
            ) {
                mediaId,
                thumbnailUrl
            }
        }`;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getMedia({ eventId, mediaId, subdomain }) {
    const query = `query {
                photo(longId: "${mediaId}", eventId: "${eventId}") {
                    mediaId,
                    longId,
                    eventId,
                    mediaUrl,
                    thumbnailUrl,
                    type,
                    captureMode
                }
            }`;

    return this._fetchFromSalsa(query, null, subdomain);
  }

  getTemplate({ eventId, mediaId, callback }) {
    return null;
  }

  transformEvent({ eventBody }) {
    const { data, errors } = eventBody;
    if (data && data.eventBasic) {
      const actionEvent = data.eventBasic;
      const eventId = data.eventBasic.eventId;

      // Format date
      const date = moment(actionEvent.eventDate).format('MMMM Do, YYYY');

      // Total number of captures in event
      const totalPhotos = actionEvent.analytics.totalPhotos;

      // Build subtitle if necessary
      let subtitle = actionEvent.subtitle;
      if (!subtitle) {
        if (totalPhotos && actionEvent.fullGalleryEnabled) {
          subtitle = `${totalPhotos} Captures`;
        } else {
          subtitle = '';
        }
      }

      // Determine background
      let background = 'none';
      if (actionEvent.backgroundUrl) {
        background = actionEvent.backgroundUrl;
      }

      // Final event object
      return {
        eventId,
        id: actionEvent.id,
        date,
        dateMoment: moment(actionEvent.eventDate),
        title: actionEvent.title,
        subtitle,
        logo: actionEvent.logoUrl,
        logoLink: actionEvent.logoLink ? actionEvent.logoLink : '',
        buttonFlag: actionEvent.buttonFlag,
        buttonText: actionEvent.buttonText,
        buttonLink: actionEvent.buttonLink,
        contactEnabled: actionEvent.contactEnabled ? actionEvent.contactEnabled : false,
        contactOwner: actionEvent.publicOwnerEmail,
        eventMedias: [],
        hasMore: true,
        fullGalleryEnabled: actionEvent.fullGalleryEnabled,
        fontColor: actionEvent.fontColor ? actionEvent.fontColor : '#000000',
        passwordProtected: actionEvent.passwordProtected,
        error: actionEvent.error,
        errorCode: actionEvent.errorCode,
        accentColor: actionEvent.micrositeAccentColor ? actionEvent.micrositeAccentColor : '#9775f6',
        contrastColor: actionEvent.micrositeAccentColor
          ? getContrastColor(actionEvent.micrositeAccentColor)
          : '#ffffff',
        background,
        organization: actionEvent.organization || { plan: 'Plus', licenseCount: 0 },
        liveGallery: actionEvent.liveGallery,
        analytics: actionEvent.analytics,
        analyticsToken: actionEvent.analyticsToken,
        audioEnabled: _.get(actionEvent, 'captureConfig.modeConfiguration.videoConfiguration.audioEnabled', false),
        virtualBoothConfig: actionEvent.virtualBoothConfig,
        captureConfig: _.get(actionEvent, 'captureConfig'),
        faceEffects: _.get(actionEvent, 'faceEffects'),
      };
    }
    if (errors && errors[0] && errors[0].code) {
      const errorCode = errors[0].code;
      return {
        code: errorCode,
      };
    }

    return null;
  }

  transformAnalytics({ eventId, analytics, surveyRows }) {
    // Squash custom survey answers with only 1 vote
    surveyRows.forEach((surveyRow) => {
      const row = surveyRow;
      const { question, answers } = row;
      const uniqueAnswers = [];
      const finalAnswers = answers.reduce((result, current) => {
        const answer = current;
        answer.value = answer.value[0].toUpperCase() + answer.value.slice(1);
        if (answer.count > 1) {
          result.push(answer);
        } else {
          uniqueAnswers.push(answer);
        }
        return result;
      }, []);
      if (uniqueAnswers.length > 4) {
        row.answers = finalAnswers;
        row.uniqueAnswers = uniqueAnswers.map((a) => a.value);
      } else {
        row.answers = finalAnswers.concat(uniqueAnswers);
        row.uniqueAnswers = [];
      }
    });

    const analyticsTransformed = {
      eventId,
      analytics: {
        totalPhotos: analytics.internal.totalPhotos,
        totalShares: analytics.internal.totalShares,
        totalViews: analytics.liveGallery.totalViewCount,
        totalCtaClicks: analytics.liveGallery.totalCtaClicks,
        totalQROpened: analytics.liveGallery.totalQROpened,
        totalDownloads: analytics.liveGallery.totalShareDownload,
        photosLast30Minutes: analytics.internal.photosLast30Minutes,
        mediaViewsAndShares: analytics.liveGallery.mediaViewsAndShares,
        captureTypes: {
          photo: {
            name: 'Photo',
            value: analytics.internal.photosByCaptureMode.photo,
          },
          boomerang: {
            name: 'Boomerang',
            value: analytics.internal.photosByCaptureMode.boomerang,
          },
          gif: {
            name: 'GIF',
            value: analytics.internal.photosByCaptureMode.gif,
          },
          video: {
            name: 'Video',
            value: analytics.internal.photosByCaptureMode.video,
          },
        },
        shareTypes: {
          email: {
            name: 'E-mail',
            value: analytics.internal.sharesEmailed,
          },
          sms: {
            name: 'SMS',
            value: analytics.internal.sharesSmsed,
          },
        },
        liveGalleryShareTypes: {
          email: {
            name: 'E-mail',
            value: analytics.liveGallery.totalShareEmail,
          },
          twitter: {
            name: 'Twitter',
            value: analytics.liveGallery.totalShareTwitter,
          },
          facebook: {
            name: 'Facebook',
            value: analytics.liveGallery.totalShareFacebook,
          },
          instagram: {
            name: 'Instagram',
            value: analytics.liveGallery.totalShareInstagram,
          },
          snapchat: {
            name: 'Snapchat',
            value: analytics.liveGallery.totalShareSnapchat,
          },
          pinterest: {
            name: 'Pinterest',
            value: analytics.liveGallery.totalSharePinterest,
          },
          reddit: {
            name: 'Reddit',
            value: analytics.liveGallery.totalShareReddit,
          },
          tumblr: {
            name: 'Tumblr',
            value: analytics.liveGallery.totalShareTumblr,
          },
          /* download: {
                        name: 'Direct Download',
                        value: analytics.liveGallery.totalShareDownload
                    } */
        },
        liveGalleryViewTypes: {
          mobile: {
            name: 'Mobile',
            value: analytics.liveGallery.totalMobileViewPerc,
          },
          tablet: {
            name: 'Tablet',
            value: analytics.liveGallery.totalTabletViewPerc,
          },
          desktop: {
            name: 'Desktop',
            value: analytics.liveGallery.totalDesktopViewPerc,
          },
        },
        surveyResponses: analytics.internal.surveyResponses,
        surveyQuestions: surveyRows,
      },
    };

    return analyticsTransformed;
  }

  transformMedia({ mediaBody }) {
    const { data } = mediaBody;
    if (data && data.photo) {
      return this.transformMedias({
        mediasBody: {
          data: {
            photosBefore: [data.photo],
          },
        },
      });
    }
    return null;
  }

  formatMedias(photos) {
    const medias = {};
    photos.forEach((photo) => {
      medias[photo.mediaId] = {};
      medias[photo.mediaId].src = photo.videoThumbnailUrl || photo.thumbnailUrl;
      medias[photo.mediaId].static = photo.thumbnailUrl;
      medias[photo.mediaId].original = photo.mediaUrl;
      medias[photo.mediaId].id = photo.mediaId;
      medias[photo.mediaId].captureMode = photo.captureMode;
      // medias[photo.publicId].publicId = photo.publicId;
      if (photo.type === 'video') {
        medias[photo.mediaId].type = 'video';
      } else {
        medias[photo.mediaId].type = 'image';
      }
      medias[photo.mediaId].isTemplate = false;
    });
    return medias;
  }

  transformMedias({ mediasBody }) {
    const { data } = mediasBody;
    let medias = null;
    if (data && (data.photosBefore || data.photosAfter || data.photos)) {
      medias = {};
    }
    if (data && data.photosBefore) {
      const { photosBefore } = data;
      medias = this.formatMedias(photosBefore);
    }
    if (data && data.photosAfter) {
      const { photosAfter } = data;
      medias = { ...medias, ...this.formatMedias(photosAfter) };
    }
    if (data && data.photos) {
      const { photos } = data;
      medias = { ...medias, ...this.formatMedias(photos) };
    }
    return medias;
  }

  logIn({ user }) {
    const { email, password } = user;
    return this._salsaRestApi('operators/login?level=2', {
      method: 'POST',
      body: { email, password },
    });
  }

  signUp({ user }) {
    return this._salsaRestApi('operators/', {
      method: 'PUT',
      body: user,
    });
  }

  resetPasswordInitiate({ email }) {
    const query = `mutation {
            resetPasswordInitiate(email: "${email}") {
                email
                isEmailInvalid
            }
        }`;

    return this._fetchFromSalsa(query);
  }

  resetPasswordValidate({ token }) {
    const query = `query {
            resetPasswordValidate(token: "${token}") {
                token
                isTokenInvalid
                isTokenExpired
            }
        }`;

    return this._fetchFromSalsa(query);
  }

  resetPasswordFinally({ token, newPassword }) {
    const query = `mutation {
            resetPasswordFinally(token: "${token}", newPassword: "${newPassword}")
        }`;

    return this._fetchFromSalsa(query);
  }

  getPlans(env) {
    return this._salsaRestApi(`/plans?stripe-env=${env}`);
  }

  getDevices({ accessToken }) {
    return this._salsaRestApi('/devices/?level=5', { method: 'GET', headers: { 'x-access-token': accessToken } });
  }

  removeDevice({ accessToken, deviceId }) {
    return this._salsaRestApi('/devices/?level=5', {
      method: 'DELETE',
      body: { deviceId },
      headers: { 'x-access-token': accessToken },
    });
  }

  sharePhoto({ eventId, mediaId, email }) {
    const query = `mutation {
            share(photoId: "${mediaId}", emailAddresses: "${email}", micrositeShare: true) {
                destination
                error
            }
        }`;
    return this._fetchFromSalsa(query, null);
  }

  passwordToken({ id, password, idType }) {
    let query;
    if (idType === 'portfolio') {
      query = `query {
                portfolioLogin(id: "${id}", password: "${password}"){
                    token
                    hint
                    error
                    errorCode
                }
            }`;
    } else {
      query = `query {
                eventLogin(id: "${id}", password: "${password}"){
                    token
                    hint
                    error
                    errorCode
                }
            }`;
    }
    return this._fetchFromSalsa(query, null);
  }

  transformPasswordToken({ tokenBody }) {
    const { data, errors } = tokenBody;
    if (data && data.eventLogin) {
      return data.eventLogin;
    }
    if (data && data.portfolioLogin) {
      return data.portfolioLogin;
    }
    if (errors && errors[0]) {
      return {
        code: errors[0],
      };
    }
    return null;
  }

  checkSubdomain(eventId, subdomain) {
    const query = `query {
        checkSubdomain(eventId: "${eventId}") {
          valid
          subdomain
        }
      }
      `;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getAppEvent({ accessToken, eventId }) {
    return this._salsaRestApi(`/events/${eventId}?level=6`, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  createEvent({ accessToken, data }) {
    const payload = data;
    if (payload.id) {
      delete payload.id;
    }
    return this._salsaRestApi('/events', { method: 'PUT', body: payload, headers: { 'x-access-token': accessToken } });
  }

  updateEvent({ accessToken, data, id }) {
    return this._salsaRestApi(`/events/${id}?level=5`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  duplicateEvent({ accessToken, id }) {
    return this._salsaRestApi(`/events/${id}/duplicate`, {
      method: 'POST',
      headers: { 'x-access-token': accessToken },
    });
  }

  deleteEvents({ accessToken, data }) {
    return this._salsaRestApi('/events', { method: 'DELETE', body: data, headers: { 'x-access-token': accessToken } });
  }

  listEvents({ accessToken, level, data }) {
    return this._salsaRestApi(`/events?level=${level || 1}`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  listMedias({ accessToken, eventId, data }) {
    return this._salsaRestApi(`/events/${eventId}/medias`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  deleteMedias({ accessToken, eventId, data }) {
    return this._salsaRestApi(`/events/${eventId}/medias`, {
      method: 'DELETE',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  async uploadAsset({ suffix, file, category = 'asset', accessToken }) {
    const { data, type } = await readInputFile(file);
    const headers = {
      'Content-Type': type,
      'asset-category': category,
    };
    if (accessToken) {
      headers['x-access-token'] = accessToken;
    }
    if (suffix) {
      headers['x-filename'] = `overlay-${suffix}`;
    }
    const result = await this._salsaRestApi('/operators/assets/upload', { method: 'PUT', body: data, headers });
    return result;
  }

  listAssets({ accessToken, data }) {
    return this._salsaRestApi('/operators/assets', {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  loadSysAssets({ category }) {
    return this._salsaRestApi('/static/sysAssets', { method: 'GET' });
  }

  deleteAssets({ accessToken, data }) {
    return this._salsaRestApi('/operators/assets', {
      method: 'DELETE',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  emailLinks({ accessToken, eventId, data }) {
    return this._salsaRestApi(`/events/${eventId}/email/links`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  emailCancellation({ accessToken, data }) {
    return this._salsaRestApi('/organizations/email/cancel', {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  addSendingDomain({ accessToken, domain }) {
    return this._salsaRestApi('/organizations/sending-domain', {
      method: 'PUT',
      body: { domain },
      headers: { 'x-access-token': accessToken },
    });
  }

  deleteSendingDomain({ accessToken }) {
    return this._salsaRestApi('/organizations/sending-domain', {
      method: 'DELETE',
      headers: { 'x-access-token': accessToken },
    });
  }

  updateOrganization({ accessToken, requestBody, organizationId }) {
    if (requestBody.pause) {
      return this._salsaRestApi('/organizations/pause', { method: 'POST', headers: { 'x-access-token': accessToken } });
    }
    return this._salsaRestApi(`/organizations/${organizationId}`, {
      method: 'POST',
      body: requestBody,
      headers: { 'x-access-token': accessToken },
    });
  }

  updateOperator({ accessToken, data }) {
    return this._salsaRestApi('/operators/me?level=6', {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  updateAcademyVideoProgress({ vimeoId, currentViewPercent, accessToken }) {
    return this._salsaRestApi('/operators/me/videoProgress', {
      method: 'POST',
      body: { vimeoId, currentViewPercent },
      headers: { 'x-access-token': accessToken },
    });
  }

  getPrintTemplate({ printTemplateId, accessToken }) {
    return this._salsaRestApi(`/operators/printTemplates/${printTemplateId}?level=3`, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  createPrintTemplate({ eventLongId, data, accessToken }) {
    let url = '/operators/printTemplates';
    if (eventLongId) {
      url += `/${eventLongId}`;
    }
    return this._salsaRestApi(url, {
      method: 'PUT',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  updatePrintTemplate({ printTemplateId, data, accessToken }) {
    const url = `/operators/printTemplates/${printTemplateId}`;
    return this._salsaRestApi(url, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  listPrintTemplates({ data, accessToken }) {
    const url = '/operators/printTemplates?level=6';
    return this._salsaRestApi(url, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  deletePrintTemplates({ ids, accessToken }) {
    const url = '/operators/printTemplates';
    return this._salsaRestApi(url, {
      method: 'DELETE',
      body: { ids },
      headers: { 'x-access-token': accessToken },
    });
  }

  listSysTemplates({ templateType, accessToken }) {
    const url = '/operators/sysTemplates?level=6';
    return this._salsaRestApi(url, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  downloadSysTemplate({ key, accessToken }) {
    const url = '/operators/sysTemplateDownload';
    return this._salsaRestApi(url, {
      method: 'POST',
      body: { key },
      headers: { 'x-access-token': accessToken },
    });
  }

  listSysFonts({ accessToken }) {
    const url = '/operators/sysFonts?level=6';
    return this._salsaRestApi(url, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  listPrintTemplateLayouts({ accessToken }) {
    const url = '/operators/printTemplates/layouts';
    return this._salsaRestApi(url, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  listPoseTips() {
    const url = '/static/poseTips/default';
    return this._salsaRestApi(url, {
      method: 'GET',
    });
  }

  getAppUserStats({ accessToken }) {
    return this._salsaRestApi('/organizations/stats', { method: 'GET', headers: { 'x-access-token': accessToken } });
  }

  listPortfolioEvents({ data, level, id }) {
    return this._salsaRestApi(`/operators/${id}/events?level=${level || 1}`, {
      method: 'POST',
      body: data,
    });
  }

  getPortfolioSettings({ id, passwordAccessToken }) {
    return this._salsaRestApi(`/operators/${id}?level=2`, {
      method: 'POST',
      body: { passwordAccessToken },
    });
  }

  getAppUserOrganization({ organizationId, accessToken }) {
    return this._salsaRestApi(`/organizations/${organizationId}?level=5`, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  getAppUserOperator({ accessToken }) {
    return this._salsaRestApi('/operators/me?level=6', { method: 'GET', headers: { 'x-access-token': accessToken } });
  }

  getAppUserPricing({ accessToken, plan }) {
    return this._salsaRestApi('/organizations/pricing?level=5', {
      method: 'POST',
      body: plan,
      headers: { 'x-access-token': accessToken },
    });
  }

  updateUserPlan({ accessToken, plan }) {
    return this._salsaRestApi('/organizations/plan', {
      method: 'POST',
      body: plan,
      headers: { 'x-access-token': accessToken },
    });
  }

  subscribe({ accessToken, plan }) {
    return this._salsaRestApi('/organizations/subscribe', {
      method: 'POST',
      body: plan,
      headers: { 'x-access-token': accessToken },
    });
  }

  cancelPlanSchedule({ accessToken }) {
    return this._salsaRestApi('/organizations/schedule', {
      method: 'DELETE',
      headers: { 'x-access-token': accessToken },
    });
  }

  verifyEmail({ token }) {
    return this._salsaRestApi('/operators/verifyEmail', {
      method: 'POST',
      body: { token },
    });
  }

  resendVerification(email) {
    return this._salsaRestApi('/operators/sendVerifyEmail', {
      method: 'POST',
      body: { email },
    });
  }

  async uploadMedia({ source, eventId, captureMode, frameRate }) {
    let data;
    let type;
    if (_.isString(source)) {
      data = { url: source, frameRate };
      type = 'application/json';
    } else {
      const file = await readInputFile(source);
      data = file.data;
      // type = file.type;
      // data = await source.arrayBuffer();
      type = source.type;
    }
    const headers = {
      'Content-Type': type,
      'x-capture-mode': captureMode,
    };
    const result = await this._salsaRestApi(`/events/${eventId}/medias/upload/public`, {
      method: 'PUT',
      body: data,
      headers,
    });
    return result;
  }

  async processMedia({ source, eventId, settings }) {
    let data;
    let type;
    if (_.isString(source)) {
      data = { url: source };
      type = 'application/json';
    } else {
      const file = await readInputFile(source);
      data = file.data;
      // data = await source.arrayBuffer();
      type = source.type;
    }

    const headers = { 'Content-Type': type };
    try {
      headers['x-settings'] = JSON.stringify(settings);
    } catch (e) {
      // TODO: Do something with this error
    }
    const result = await this._salsaRestApi(`/events/${eventId}/medias/process/public`, {
      method: 'PUT',
      body: data,
      headers,
    });

    /* const options = {
            method: 'PUT',
            url: this.URL.replace(/\/gql$/, '') + `/events/${eventId}/medias/process/public`,
            data, headers
        };
        const res = await axios(options, { timeout: 300e3 }).then(r => r.data); */
    // console.log(res)
    // return res;

    if (!result.ok) {
      const { error } = await result.json();
      throw new Error(error);
    }
    if (/^text/i.test(result.headers.get('Content-Type'))) {
      const txt = (await result.text()).split('|');
      const err = txt.filter((line) => /^error/.test(line)).map((line) => JSON.parse(line.split('error:')[1]))[0];
      const data = txt.filter((line) => /^data/.test(line)).map((line) => JSON.parse(line.split('data:')[1]))[0];
      // console.log('result:', err, data);
      if (err) {
        throw new Error(err);
      }
      if (!data) {
        throw new Error('No data');
      }
      return data;
    }
    /* if (/application\/json/i.test(result.headers.get('Content-Type'))) {
            return result.json();
        } */

    const b = await result.blob();
    const url = URL.createObjectURL(b);
    return { url };
  }

  updateEnvironment({ env }) {
    let url;
    if (env) {
      url = require('../constants').get(env).salsaURL;
    } else {
      url = require('../constants').get().salsaURL;
    }
    this.URL = url;
  }

  checkForMedia({ sourceId }) {
    return this._salsaRestApi(`/qr/${sourceId}`, {
      method: 'GET',
    });
  }

  submitPendingShare({ sourceId, target, shareType }) {
    return this._salsaRestApi('/qr', {
      method: 'PUT',
      body: {
        sourceId,
        type: shareType,
        target,
      },
    });
  }

  getVirtualBooth({ eventId }) {
    return this._salsaRestApi(`/events/${eventId}/virtualBooth?level=5`, {
      method: 'GET',
    });
  }

  getS3FormData({ eventId, contentType, accessToken }) {
    return this._salsaRestApi(`/events/${eventId}/medias/upload_data`, {
      method: 'POST',
      body: { contentType },
      headers: { 'x-access-token': accessToken },
    });
  }

  saveCapture({ eventId, filename, captureMode, captureTime, accessToken, uploadSource }) {
    return this._salsaRestApi(`/events/${eventId}/medias/upload_save`, {
      method: 'POST',
      body: { filename, captureMode, uploadSource },
      headers: { 'x-access-token': accessToken },
    });
  }

  getShopifyCustomerEmail({ queryStr, accessToken }) {
    return this._salsaRestApi('/operators/shopifyCustomer', {
      method: 'POST',
      body: { query_str: queryStr },
      headers: { 'x-access-token': accessToken },
    });
  }

  getMarketingMaterials({ accessToken }) {
    return this._salsaRestApi('/operators/marketingMaterials', {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  downloadMarketingMaterial({ key, accessToken }) {
    return this._salsaRestApi('/operators/marketingMaterialDownload', {
      method: 'POST',
      body: { key },
      headers: { 'x-access-token': accessToken },
    });
  }

  getProposalData({ eventId }) {
    return this._salsaRestApi(`/events/${eventId}/proposal?level=6`, {
      method: 'GET',
    });
  }

  listCountries() {
    return this._salsaRestApi('/countries', {
      method: 'GET',
    });
  }

  _fetchFromSalsa(query, accessToken, subdomain) {
    const headers = {
      'Content-Type': 'application/json',
      'x-app-version': 'web',
      'x-sse-source': sseSource,
    };

    if (accessToken) {
      headers['x-access-token'] = accessToken;
    }
    if (
      subdomain &&
      !['dev', 'beta', 'staging', 'www', 'boothpics', 'localboothpics'].includes(subdomain) &&
      !subdomain.startsWith('localhost')
    ) {
      headers['X-Subdomain'] = subdomain;
    }

    return fetch(`${this.URL}`, {
      method: 'POST',
      body: JSON.stringify({ query }),
      headers,
    });
  }

  _salsaRestApi(path, options) {
    const isProd = publicRuntimeConfig.DOMAIN === 'prod';
    const params = _.defaultsDeep(options, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-app-version': 'web',
        'x-sse-source': sseSource,
      },
    });
    if (typeof params.body === 'object' && params.headers['Content-Type'] === 'application/json') {
      params.body = JSON.stringify(params.body);
    }
    const id = uniqid();
    const t = Date.now();
    if (!isProd) {
      const logOptions = _.clone(params);
      if (logOptions.body && !_.isString(logOptions.body)) {
        logOptions.body = typeof logOptions.body;
      }
    }
    return fetch(`${this.URL.replace(/\/gql$/, '')}/${path.replace(/^\/+/, '')}`, params).then(async (res) => {
      // Handle unauthorized (401) errors
      if (res.status === 401) {
        const clone = res.clone();
        const data = await clone.json();
        if (!data.errors) {
          return res;
        }
        // Handle unauthorized user trying to access non-production environment
        if (!isProd && data.errors[0].message.includes('Invalid billing environment')) {
          // Redirect to prod web app
          window.location.href = `${prodWebAppURL}/admin/events`;
          return;
        }
        // Handle invalid access token
        if (data.errors.find((err) => err.message === 'Invalid access token.')) {
          // Remove invalid cookie and redirect to login page
          Cookies.remove('operator');
          window.location.href = `${webAppURL}/login`;
          return;
        }
      }
      return res;
    });
  }
}

export default BackendSalsa;
