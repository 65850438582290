import autoBind from 'auto-bind';
import { all, put, takeEvery, delay, takeLatest, select, cancelled } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import * as actionCreator from '../actions/actions';
import * as marketingActions from '../actions/marketing';
import * as proposalActions from '../actions/proposal';
import { LIST_COUNTRIES, listCountriesRequest, listCountriesSuccess, listCountriesFailure } from '../actions/countries';
import { getEvent, getMedia, getTemplate, getFilters, getEnvironment } from '../selectors/selectors';
import utils from '../utils/utils';
import { loadFonts } from '../utils/fonts';

class Sagas {
  constructor(backend) {
    this.backend = backend;
    autoBind(this);
  }

  *setBackendUrl() {
    const env = yield select(getEnvironment);
    if (env === 'prod' || env === 'beta' || env === 'staging' || env === 'dev' || env === 'local') {
      this.backend.updateEnvironments({ env });
    } else {
      this.backend.updateEnvironments({});
    }
  }

  *requestEvent(action) {
    const { eventId, subdomain } = action;
    const apiName = utils.getApiName(eventId);
    let token = '';
    if (!action.isServer) {
      token = utils.retrieveFromLocalStorage(`eventToken${eventId}`);
    }
    if (apiName) {
      yield put(actionCreator.eventNotFound(false));
      const event = yield select(getEvent, eventId);

      if (typeof event !== 'undefined' && event !== null) {
        yield put(actionCreator.activeEvent(event));
      } else {
        yield put(actionCreator.loadingEvent());
        const eventResult = yield this.backend.getEvent({ eventId, token, apiName, subdomain });
        const eventBody = yield eventResult.json();
        const eventTransformed = this.backend.transformEvent({ eventBody, apiName });
        if (eventTransformed === null) {
          yield put(actionCreator.eventNotFound(true));
        } else if (eventTransformed.errorCode === 2001 || eventTransformed.errorCode === 2002) {
          yield put(actionCreator.activeEvent(eventTransformed));
          yield put(actionCreator.requirePassword(eventId, eventTransformed.errorCode));
        } else {
          yield put(actionCreator.passwordNotRequired(eventId));
          yield put(actionCreator.retrievedEvent(eventTransformed));
          yield put(actionCreator.activeEvent(eventTransformed));
        }
      }
    } else {
      put(actionCreator.eventNotFound(true));
    }
    yield put(actionCreator.finishedEvent());
  }

  *requestEventAnalytics(action) {
    const { eventId, analyticsToken, subdomain } = action;
    const apiName = utils.getApiName(eventId);

    if (apiName) {
      yield put(actionCreator.eventNotFound(false));
      const analyticsResult = yield this.backend.getEventAnalytics({ analyticsToken, eventId, apiName });
      const analyticsBody = yield analyticsResult.json();

      if (analyticsBody && analyticsBody.data) {
        const analytics = analyticsBody.data;

        if (!analytics) {
          yield put(actionCreator.eventNotFound(true));
          return;
        }

        // Retrieve thumbnail URLs for top viewed media
        if (analytics.liveGallery.mediaViewsAndShares) {
          const topMedia = analytics.liveGallery.mediaViewsAndShares.slice(0, 20);
          const mediaIds = topMedia.map((media) => media.mediaId);
          const mediasResult = yield this.backend.getMediasByIds({ eventId, mediaIds, apiName });
          const mediasBody = yield mediasResult.json();
          const medias = mediasBody.data.photos;

          if (medias) {
            for (let i = 0; i < topMedia.length; i += 1) {
              const media = medias.find((el) => {
                return el.mediaId === topMedia[i].mediaId;
              });
              if (media && media.thumbnailUrl) {
                topMedia[i].url = media.thumbnailUrl;
              }
            }
          }
        }

        // Retrieve survey results
        const surveyParams = {
          eventId,
          apiName,
          subdomain,
        };
        const surveyResult = yield this.backend.getSurveyResults(surveyParams);
        const surveyBody = yield surveyResult.json();
        let surveyRows = [];
        if (surveyBody.data && surveyBody.data.surveyResults) {
          surveyRows = surveyBody.data.surveyResults.rows;
        }

        const analyticsTransformed = this.backend.transformAnalytics({
          analytics,
          analyticsToken,
          surveyRows,
          apiName,
        });
        yield put(actionCreator.retrievedEventAnalytics(analyticsTransformed));
      } else {
        put(actionCreator.eventNotFound(true));
      }
    } else {
      put(actionCreator.eventNotFound(true));
    }
  }

  *requestEventTopMedias(action) {
    const { eventId, sortParam, analyticsToken } = action;
    const apiName = utils.getApiName(eventId);

    yield put(actionCreator.loadingMedias(true));

    if (apiName) {
      yield put(actionCreator.eventNotFound(false));
      const analyticsResult = yield this.backend.getEventAnalytics({ eventId, analyticsToken, apiName });
      const analyticsBody = yield analyticsResult.json();

      if (analyticsBody) {
        const analytics = analyticsBody.data;

        if (!analytics) {
          yield put(actionCreator.eventNotFound(true));
          return;
        }

        // Retrieve thumbnail URLs for top media
        const topMedia = analytics.liveGallery.mediaViewsAndShares
          .sort((a, b) => {
            return parseInt(b[sortParam], 10) - parseInt(a[sortParam], 10);
          })
          .slice(0, 20);
        const mediaIds = topMedia.map((media) => media.mediaId);
        const mediasResult = yield this.backend.getMediasByIds({ eventId, mediaIds, apiName });
        const mediasBody = yield mediasResult.json();
        const medias = mediasBody.data.photos;

        if (medias) {
          for (let i = 0; i < topMedia.length; i += 1) {
            const media = medias.find((el) => {
              return el.mediaId === topMedia[i].mediaId;
            });
            if (media && media.thumbnailUrl) {
              topMedia[i].url = media.thumbnailUrl;
            }
          }
        }

        yield put(actionCreator.retrievedEventTopMedias(topMedia));
      } else {
        put(actionCreator.eventNotFound(true));
      }
    } else {
      put(actionCreator.eventNotFound(true));
    }

    yield put(actionCreator.loadingMedias(false));
  }

  *requestMedia(action) {
    yield put(actionCreator.loadingMedia(true));
    yield put(actionCreator.mediaNotFound(false));
    const media = yield select(getMedia, action.mediaId);
    const { eventId, mediaId, subdomain } = action;
    if (typeof media !== 'undefined' && media !== null) {
      yield put(actionCreator.activeMedia(media));
      yield put(actionCreator.loadingMedia(false));
      if (media.isTemplate) {
        const templateMedia = yield select(getTemplate, action.mediaId);
        if (typeof templateMedia !== 'undefined' && templateMedia !== null) {
          yield put(actionCreator.updateDownloadLink(media.original));
          return;
        }
      } else {
        yield put(actionCreator.updateDownloadLink(media.original));
        return;
      }
    }
    yield put(actionCreator.updateDownloadLink('unavailable'));

    const apiName = utils.getApiName(action.eventId);
    // Return if event ID is invalid
    if (!apiName) {
      yield put(actionCreator.mediaNotFound(true));
      yield put(actionCreator.loadingMedia(false));
      return;
    }

    const response = yield this.backend.getMedia({ eventId, mediaId, apiName, subdomain });
    const mediaBody = yield response.json();
    const mediaTransformed = this.backend.transformMedia({ mediaBody, apiName });

    if (!mediaTransformed || !mediaTransformed[mediaId]) {
      yield put(actionCreator.mediaNotFound(true));
      yield put(actionCreator.loadingMedia(false));
      return;
    }

    yield put(actionCreator.activeMedia(mediaTransformed[mediaId]));

    if (mediaTransformed === null) {
      yield put(actionCreator.mediaNotFound(true));
    } else {
      yield put(actionCreator.retrievedMedias(mediaTransformed));
      yield put(actionCreator.updateDownloadLink(mediaTransformed[mediaId].original));
      if (mediaTransformed[mediaId].isTemplate) {
        const templateResponse = yield this.backend.getTemplate({ eventId, mediaId, apiName });
        const templateBody = yield templateResponse.json();
        yield put(actionCreator.retrievedTemplate(templateBody, mediaId));
      }
    }
    yield put(actionCreator.loadingMedia(false));
  }

  *requestMedias(action) {
    yield put(actionCreator.loadingMedias(true));
    const apiName = utils.getApiName(action.eventId);
    const { subdomain } = action;
    if (apiName) {
      let filters = yield select(getFilters);
      const event = yield select(getEvent, action.eventId);
      if (typeof event !== 'undefined' || event != null) {
        yield put(actionCreator.mediaNotFound(false));
      }
      let eventMedias = [];
      if (!action.isServer) {
        const event = yield select(getEvent, action.eventId);
        if (event) {
          eventMedias = event.eventMedias;
        }
      }
      const { eventId } = action;

      if (typeof filters !== 'object' || !filters.order) {
        filters = {
          captureMode: null,
          dateFrom: null,
          dateTo: null,
          filterByCaptureMode: false,
          filterByTime: false,
          isMobile: false,
          order: 'DESC',
        };
      }

      const backendFilterString = this._createBackendFilterString(filters, eventMedias, apiName);

      const mediasResults = yield this.backend.getMediasWithFilters({
        eventId,
        eventMedias,
        apiName,
        subdomain,
        filters: backendFilterString,
      });

      const mediasBody = yield mediasResults.json();
      const mediasTransformed = this.backend.transformMedias({ mediasBody, apiName });
      yield put(actionCreator.retrievedMedias(mediasTransformed));

      let mediaFetched = [];
      if (apiName === 'queso') {
        mediaFetched = mediasBody.map((media) => {
          return { mediaId: media.mediaId };
        });
      } else {
        if (mediasBody.data && mediasBody.data.photosBefore) {
          mediaFetched = mediasBody.data.photosBefore.map((media) => {
            return { mediaId: media.mediaId, date: media.dateCreated };
          });
        }
        if (mediasBody.data && mediasBody.data.photosAfter) {
          mediaFetched = mediaFetched.concat(
            mediasBody.data.photosAfter.map((media) => {
              return { mediaId: media.mediaId, date: media.dateCreated };
            })
          );
        }
        if (mediasBody.data && mediasBody.data.photos) {
          mediaFetched = mediasBody.data.photos.map((media) => {
            return { mediaId: media.mediaId, date: media.dateCreated };
          });
        }
      }
      if (mediaFetched.length === 0) {
        yield put(actionCreator.eventHasNoMoreMedia(eventId));
      }
      yield put(actionCreator.updateEventMedias(eventId, mediaFetched));
      yield put(actionCreator.loadingMedias(false));
    } else {
      yield put(actionCreator.loadingMedias(false));
      yield put(actionCreator.mediaNotFound(false));
    }
  }

  *sharePhotoByEmail(action) {
    yield put(actionCreator.sharingPhotoRequest());
    const { mediaId, eventId, email } = action;
    const apiName = utils.getApiName(action.eventId);
    const result = yield this.backend.sharePhoto({ eventId, mediaId, email, apiName });
    const resultBody = yield result.json();
    if (result.status === 200) {
      yield put(actionCreator.sharingPhotoSuccess(resultBody));
    } else {
      yield put(actionCreator.sharingPhotoFailure());
    }
  }

  *requestPlans({ env }) {
    yield put(actionCreator.accountFetchRequest());
    yield put(actionCreator.fetchPlansRequest());
    const apiName = 'salsa';
    try {
      const result = yield this.backend.requestPlans({ apiName, env });
      if (result) {
        const { data } = yield result.json();

        if (data && data.plans) {
          data.plans.forEach((plan) => {
            plan.cost /= 100;
          });
          yield put(actionCreator.fetchPlansSuccess(data));
        } else {
          yield put(actionCreator.fetchPlansFailure());
        }
      } else {
        yield put(actionCreator.fetchPlansFailure());
      }
      yield put(actionCreator.accountFetchFinished());
    } catch (error) {
      yield put(actionCreator.fetchPlansFailure());
    }
  }

  *requestDevices({ accessToken }) {
    yield put(actionCreator.accountFetchRequest());
    yield put(actionCreator.fetchDevicesRequest());
    const apiName = 'salsa';
    const result = yield this.backend.requestDevices({ accessToken, apiName });
    if (result && result.status === 200) {
      const body = yield result.json();
      yield put(actionCreator.fetchDevicesSuccess(body.data));
    } else {
      yield put(actionCreator.fetchDevicesFailure());
    }
    yield put(actionCreator.accountFetchFinished());
  }

  *requestRemoveDevice({ accessToken, deviceId }) {
    yield put(actionCreator.removeDeviceRequest());
    const apiName = 'salsa';
    const result = yield this.backend.removeDevice({ accessToken, deviceId, apiName });
    if (result && result.status === 200) {
      const body = yield result.json();
      yield put(actionCreator.removeDeviceSuccess(body.data));
    } else {
      yield put(actionCreator.removeDeviceFailure());
    }
  }

  *requestProfile(action) {
    if (action.reRender) {
      yield put(actionCreator.accountFetchRequest());
    }
    try {
      yield put(actionCreator.fetchProfileRequest(action.reRender));
      const { organizationId, accessToken } = action;
      const data = yield this.backend.requestProfile({ organizationId, accessToken });
      yield put(actionCreator.fetchProfileSuccess(data, action.reRender));
    } catch (error) {
      yield put(actionCreator.fetchProfileFailure(true, error));
    }
    if (action.reRender) {
      yield put(actionCreator.accountFetchFinished());
    }
  }

  *requestInvoices(action) {
    const { organizationId } = action;
    yield put(actionCreator.accountFetchRequest());
    yield put(actionCreator.fetchInvoicesRequest());
    const apiName = 'billing';
    const result = yield this.backend.requestInvoices({ organizationId, apiName });
    const resultBody = yield result.json();
    if (result.status === 200) {
      if (resultBody) {
        yield put(actionCreator.fetchInvoicesSuccess(resultBody));
      } else {
        yield put(actionCreator.fetchInvoicesFailure());
      }
    } else {
      yield put(actionCreator.fetchInvoicesFailure());
    }
    yield put(actionCreator.accountFetchFinished());
  }

  *updateOrganization({ organizationId, requestBody, accessToken }) {
    yield put(actionCreator.updateOrganizationRequest());
    let apiName = 'billing';
    if (!_.isEmpty(accessToken)) {
      apiName = 'salsa';
    }
    const result = yield this.backend.updateOrganization({
      organizationId,
      requestBody,
      apiName,
      accessToken,
    });
    const body = yield result.json();
    if (result && result.status === 200) {
      yield put(actionCreator.updateOrganizationSuccess(body));
    } else {
      const error = _.get(body, 'errors[0].message');
      yield put(actionCreator.updateOrganizationFailure(error || body));
    }
  }

  *resetOrganizationStore(action) {
    yield put(actionCreator.updateOrganizationDefault());
  }

  *signUp({ user }) {
    yield put(actionCreator.signUpRequest());
    const apiName = 'salsa';
    try {
      const result = yield this.backend.signUp({ user, apiName });
      if (result) {
        const signUpBody = yield result.json();
        if (signUpBody.data) {
          if (!user.persona) {
            yield put(actionCreator.signUpSuccess(signUpBody.data));
            return;
          }
          try {
            // Submit data to Hubspot to create/update a contact
            const hubspotResult = yield fetch(
              'https://api.hsforms.com/submissions/v3/integration/submit/1555762/32df02af-1bc5-4fea-bb25-7c45074c1d02',
              {
                method: 'POST',
                body: JSON.stringify({
                  submittedAt: Date.now().toString(),
                  context: {
                    pageUri: window.location.href,
                    pageName: document.title,
                  },
                  fields: [
                    { name: 'email', value: user.email },
                    { name: 'hs_persona', value: user.persona },
                  ],
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'xmlhttprequest',
                },
              }
            );
          } catch (error) {
            // TODO: Do something with this error
          } finally {
            yield put(actionCreator.signUpSuccess(signUpBody.data));
          }
        } else {
          let errorMessage = signUpBody.errors[0].message;
          // TODO: just return non-messy errors from the backend to avoid
          //       all this string replacement
          errorMessage = errorMessage.replace('Operator validation failed: ', '');
          errorMessage = errorMessage.replace('email: invalid email', 'Invalid email address');
          errorMessage = errorMessage.replace('phoneNumber: invalid phone number', 'Invalid phone number');
          yield put(actionCreator.signUpFailure(errorMessage));
        }
      } else {
        yield put(actionCreator.signUpFailure('An unexpected error occurred. Please contact support.'));
      }
    } catch (error) {
      yield put(actionCreator.signUpFailure('An unexpected error occurred. Please contact support.'));
    }
  }

  *logIn({ user }) {
    yield put(actionCreator.logInRequest());
    const apiName = 'salsa';
    try {
      const result = yield this.backend.logIn({ user, apiName });
      if (result) {
        const logInBody = yield result.json();
        if (logInBody.data) {
          const {
            data,
            data: { organization },
          } = logInBody;
          const loggedUser = {
            email: user.email,
            token: data.accessToken,
            organizationId: data.organizationId,
            ownerId: data.operatorId,
            operatorId: data.operatorId,
            newUser: !organization.lastCancelAt && !organization.plan,
            superUser: false,
          };
          utils.saveToLocalStorage('token', loggedUser);
          const cookieOpts = {};
          if (user.remember) {
            cookieOpts.expires = moment().add(1, 'month').toDate();
          }
          Cookies.set(
            'operator',
            {
              accessToken: loggedUser.token,
              operatorId: loggedUser.operatorId,
              organizationId: loggedUser.organizationId,
              email: loggedUser.email,
              newUser: loggedUser.newUser,
              superUser: false,
            },
            cookieOpts
          );
          yield put(actionCreator.logInSuccess(logInBody.data));
        } else {
          // Incorrect email or password
          yield put(actionCreator.logInIncorrect(logInBody.errors));
        }
      } else {
        yield put(actionCreator.logInFailure());
      }
    } catch (error) {
      yield put(actionCreator.logInFailure());
    }
  }

  *requestPricing({ data }) {
    try {
      yield put(actionCreator.fetchPricingRequest());
      const result = yield this.backend.requestPricing(data);
      if (result) {
        const pricingBody = yield result.json();
        if (pricingBody) {
          yield put(actionCreator.fetchPricingSuccess(pricingBody));
        } else {
          yield put(actionCreator.fetchPricingFailure());
        }
      } else {
        yield put(actionCreator.fetchPricingFailure());
      }
    } catch (error) {
      yield put(actionCreator.fetchPricingFailure());
    }
  }

  *resetPasswordInitiate({ email }) {
    yield put(actionCreator.resetPasswordInitiateRequest());
    const apiName = 'salsa';
    try {
      const result = yield this.backend.resetPasswordInitiate({ email, apiName });
      if (result && result.status === 200) {
        const body = yield result.json();
        const data = body.data.resetPasswordInitiate;
        yield put(actionCreator.resetPasswordInitiateSuccess(data));
      } else {
        yield put(actionCreator.resetPasswordInitiateFailure());
      }
    } catch (error) {
      yield put(actionCreator.resetPasswordInitiateFailure());
    }
  }

  *resetPasswordValidate({ token }) {
    yield put(actionCreator.resetPasswordValidateRequest({ token }));
    const apiName = 'salsa';
    try {
      const result = yield this.backend.resetPasswordValidate({ token, apiName });
      if (result && result.status === 200) {
        const body = yield result.json();
        const data = body.data.resetPasswordValidate;
        yield put(actionCreator.resetPasswordValidateSuccess(data));
      } else {
        yield put(actionCreator.resetPasswordValidateFailure({ token }));
      }
    } catch (error) {
      yield put(actionCreator.resetPasswordValidateFailure({ token }));
    }
  }

  *resetPasswordFinally({ token, newPassword }) {
    yield put(actionCreator.resetPasswordFinallyRequest());
    const apiName = 'salsa';
    try {
      const result = yield this.backend.resetPasswordFinally({ token, newPassword, apiName });
      if (result && result.status === 200) {
        yield put(actionCreator.resetPasswordFinallySuccess());
      } else {
        yield put(actionCreator.resetPasswordFinallyFailure());
      }
    } catch (error) {
      yield put(actionCreator.resetPasswordFinallyFailure());
    }
  }

  *requirePassword() {
    yield put(actionCreator.requirePasswordRequest());
  }

  *passwordToken({ id, password, idType }) {
    yield put(actionCreator.passwordTokenRequest());
    const apiName = 'salsa';
    try {
      const tokenResult = yield this.backend.passwordToken({ id, idType, password, apiName });
      const tokenBody = yield tokenResult.json();
      if (tokenBody) {
        const token = this.backend.transformPasswordToken({ tokenBody, apiName });
        if (token === null || token.errorCode === 2003) {
          yield put(actionCreator.passwordTokenFailure(token));
        } else {
          yield put(actionCreator.passwordTokenSuccess(token));
        }
      } else {
        yield put(actionCreator.passwordTokenFailure(null));
      }
    } catch (error) {
      yield put(actionCreator.passwordTokenFailure(null));
    }
  }

  // FOR ANALYTICS
  *incrementShareClicks(action) {
    const { body } = action;
    const apiName = 'salsa';
    try {
      const result = yield this.backend.incrementShareClicks({ body, apiName });
    } catch (error) {
      // TODO: Do something with this error
    }
  }

  *getEvent({ eventId, accessToken }) {
    yield put(actionCreator.getEventRequest());
    try {
      const result = yield this.backend.getAppEvent({ eventId, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.getEventSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.getEventFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.getEventFailure(error.message));
    }
  }

  *getSseEvent({ eventId, accessToken }) {
    yield put(actionCreator.getSseEventRequest());
    try {
      const result = yield this.backend.getAppEvent({ eventId, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.getSseEventSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.getSseEventFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.getSseEventFailure(error.message));
    }
  }

  *createEvent({ data, accessToken }) {
    yield put(actionCreator.createEventRequest());
    try {
      const result = yield this.backend.createEvent({ data, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.createEventSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.createEventFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.createEventFailure(error.message));
    }
  }

  *updateEvent({ id, data, accessToken }) {
    yield put(actionCreator.updateEventRequest());
    // yield delay(1000);
    try {
      // Make sure handsFreeConfiguration isn't null
      // Fixes bug #308
      const payload = data;
      if (payload.captureConfig) {
        if (!payload.captureConfig.handsFreeConfiguration) {
          payload.captureConfig.handsFreeConfiguration = undefined;
        }
      }
      const result = yield this.backend.updateEvent({ id, data: payload, accessToken });
      const body = yield result.json();
      // const fields = Object.keys(data).join(', ');
      if (body.data) {
        yield put(actionCreator.updateEventSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.updateEventFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.updateEventFailure(error.message));
    }
  }

  *duplicateEvent({ id, accessToken }) {
    yield put(actionCreator.duplicateEventRequest());
    try {
      const result = yield this.backend.duplicateEvent({ id, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.duplicateEventSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.duplicateEventFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.duplicateEventFailure(error.message));
    }
  }

  *deleteEvents({ ids, accessToken }) {
    yield put(actionCreator.deleteEventsRequest());
    try {
      const result = yield this.backend.deleteEvents({ data: { ids }, accessToken });
      const body = yield result.json();
      if (body.data && body.data.toLowerCase() === 'success') {
        yield put(actionCreator.deleteEventsSuccess());
      } else if (body.errors) {
        yield put(actionCreator.deleteEventsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.deleteEventsFailure(error.message));
    }
  }

  *listEvents({ data, level, accessToken }) {
    yield put(actionCreator.listEventsRequest());
    try {
      const result = yield this.backend.listEvents({ data, level, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.listEventsSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.listEventsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listEventsFailure(error.message));
    }
  }

  *listSampleEvents({ level, accessToken }) {
    yield put(actionCreator.listSampleEventsRequest());
    try {
      const result = yield this.backend.listEvents({
        data: {
          type: 'demo',
          pagination: { count: 8 },
        },
        level,
        accessToken,
      });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.listSampleEventsSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.listSampleEventsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listSampleEventsFailure(error.message));
    }
  }

  *listMedias({ data, eventId, accessToken }) {
    yield put(actionCreator.listMediasRequest());
    // yield delay(1000);
    try {
      // throw new Error('Test error');
      const result = yield this.backend.listMedias({ data, eventId, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.listMediasSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.listMediasFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listMediasFailure(error.message));
    }
  }

  *deleteMedias({ eventId, ids, accessToken }) {
    yield put(actionCreator.deleteMediasRequest());
    try {
      const result = yield this.backend.deleteMedias({ eventId, data: { ids }, accessToken });
      const body = yield result.json();
      if (body.data && body.data.toLowerCase() === 'success') {
        yield put(actionCreator.deleteMediasSuccess());
      } else if (body.errors) {
        yield put(actionCreator.deleteMediasFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.deleteMediasFailure(error.message));
    }
  }

  *emailLinks({ eventId, data, accessToken }) {
    yield put(actionCreator.emailLinksRequest());
    try {
      const result = yield this.backend.emailLinks({ eventId, data, accessToken });
      const body = yield result.json();
      if (body.data && body.data.toLowerCase() === 'ok') {
        yield put(actionCreator.emailLinksSuccess());
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.emailLinksFailure(error.message));
    }
  }

  *emailCancellation({ data, accessToken }) {
    yield put(actionCreator.emailCancellationRequest());
    try {
      const result = yield this.backend.emailCancellation({ data, accessToken });
      const body = yield result.json();
      if (body.data && body.data.toLowerCase() === 'ok') {
        yield put(actionCreator.emailCancellationSuccess());
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.emailCancellationFailure(error.message));
    }
  }

  *uploadAsset({ file, suffix, category = 'asset', accessToken }) {
    yield put(actionCreator.uploadAssetRequest());
    // yield delay(1000);
    try {
      // throw new Error('Test error');
      const result = yield this.backend.uploadAsset({ file, suffix, category, accessToken: accessToken || null });
      const body = yield result.json();
      const assetUrl = _.get(body, 'data.assetUrl');
      if (!_.isEmpty(assetUrl)) {
        yield put(actionCreator.uploadAssetSuccess(assetUrl));
      } else if (body.errors) {
        yield put(actionCreator.uploadAssetFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.uploadAssetFailure(error.message));
    }
  }

  *listAssets({ data, accessToken }) {
    yield put(actionCreator.listAssetsRequest());
    try {
      const result = yield this.backend.listAssets({ data, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.listAssetsSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.listAssetsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listAssetsFailure(error.message));
    }
  }

  *deleteAssets({ ids, accessToken }) {
    yield put(actionCreator.deleteAssetsRequest());
    try {
      const result = yield this.backend.deleteAssets({ data: { ids }, accessToken });
      const body = yield result.json();
      if (body.data && body.data.toLowerCase() === 'success') {
        yield put(actionCreator.deleteAssetsSuccess(ids));
      } else if (body.errors) {
        yield put(actionCreator.deleteAssetsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.deleteAssetsFailure(error.message));
    }
  }

  *loadSysAssets({ category }) {
    yield put(actionCreator.loadSysAssetsRequest());
    try {
      const result = yield this.backend.loadSysAssets({ category });
      const body = yield result.json();
      if (body.data) {
        // Filter by category
        let data = body.data;
        if (category) {
          data = body.data.filter((asset) => asset.category.includes(category));
        }
        yield put(actionCreator.loadSysAssetsSuccess(data));
      } else if (body.errors) {
        yield put(actionCreator.loadSysAssetsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.loadSysAssetsFailure(error.message));
    }
  }

  *addSendingDomain({ domain, accessToken }) {
    yield put(actionCreator.addSendingDomainRequest());
    try {
      const result = yield this.backend.addSendingDomain({ domain, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.addSendingDomainSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.addSendingDomainFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.addSendingDomainFailure(error.message));
    }
  }

  *deleteSendingDomain({ domain, accessToken }) {
    yield put(actionCreator.deleteSendingDomainRequest());
    // yield delay(1000);
    try {
      // throw new Error('Test error');
      const result = yield this.backend.deleteSendingDomain({ domain, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.deleteSendingDomainSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.deleteSendingDomainFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.deleteSendingDomainFailure(error.message));
    }
  }

  *updateOperator({ data, accessToken }) {
    yield put(actionCreator.updateOperatorRequest());
    try {
      const result = yield this.backend.updateOperator({ data, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.updateOperatorSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.updateOperatorFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.updateOperatorFailure(error.message));
    }
  }

  *updateAcademyVideoProgress({ vimeoId, currentViewPercent, accessToken }) {
    yield put(actionCreator.updateAcademyVideoProgressRequest());
    try {
      const result = yield this.backend.updateAcademyVideoProgress({ vimeoId, currentViewPercent, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.updateAcademyVideoProgressSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.updateAcademyVideoProgressFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.updateAcademyVideoProgressFailure(error.message));
    }
  }

  *storeCheckoutData(action) {
    yield put(actionCreator.storeCheckoutDataRequest(action.data));
  }

  *getSseProfile({ organizationId, accessToken }) {
    try {
      yield put(actionCreator.getSseProfileRequest());
      const data = yield this.backend.requestProfile({ organizationId, accessToken });
      yield put(actionCreator.getSseProfileSuccess(data));
    } catch (error) {
      yield put(actionCreator.getSseProfileFailure());
    }
  }

  *getPrintTemplate({ printTemplateId, accessToken }) {
    if (printTemplateId && accessToken) {
      try {
        yield put(actionCreator.getPrintTemplateRequest());
        const result = yield this.backend.getPrintTemplate({ printTemplateId, accessToken });
        const resultBody = yield result.json();
        if (result.status === 200 && resultBody && resultBody.data) {
          // Add all necessary fonts to document
          const sysFontsReq = yield this.backend.listSysFonts({ accessToken });
          const sysFonts = yield sysFontsReq.json();
          const fontNames = [...new Set(resultBody.data.texts.map((t) => t.font))];
          const fonts = fontNames.map((name) => sysFonts.data.find((f) => f.name === name)).filter((f) => f);
          const fontsLoaded = yield loadFonts(fonts);
          yield put(actionCreator.getPrintTemplateSuccess(resultBody.data));
        } else {
          throw new Error('Invalid server response');
        }
      } catch (error) {
        yield put(actionCreator.getPrintTemplateFailure());
      }
    }
  }

  *createPrintTemplate({ eventLongId = null, data, accessToken }) {
    if (data && accessToken) {
      try {
        yield put(actionCreator.createPrintTemplateRequest());
        const result = yield this.backend.createPrintTemplate({ eventLongId, data, accessToken });
        const resultBody = yield result.json();
        if (result.status === 200 && resultBody && resultBody.data) {
          yield put(actionCreator.createPrintTemplateSuccess(resultBody.data));
        } else if (resultBody.errors) {
          yield put(actionCreator.createPrintTemplateFailure(resultBody.errors[0].message));
        } else {
          throw new Error('Invalid server response');
        }
      } catch (error) {
        yield put(actionCreator.createPrintTemplateFailure(error));
      }
    }
  }

  *updatePrintTemplate({ printTemplateId, data, accessToken }) {
    if (printTemplateId && data && accessToken) {
      try {
        yield put(actionCreator.updatePrintTemplateRequest());
        const result = yield this.backend.updatePrintTemplate({ printTemplateId, data, accessToken });
        const resultBody = yield result.json();
        if (result.status === 200 && resultBody && resultBody.data) {
          yield put(actionCreator.updatePrintTemplateSuccess(resultBody.data));
        } else if (resultBody.errors) {
          yield put(actionCreator.updatePrintTemplateFailure(resultBody.errors[0].message));
        } else {
          throw new Error('Invalid server response');
        }
      } catch (error) {
        yield put(actionCreator.updatePrintTemplateFailure());
      }
    }
  }

  *listPrintTemplates({ data, accessToken }) {
    try {
      yield put(actionCreator.listPrintTemplatesRequest());
      const result = yield this.backend.listPrintTemplates({ data, accessToken });
      const resultBody = yield result.json();
      if (resultBody && resultBody.data) {
        // Add all necessary fonts to document
        const sysFontsReq = yield this.backend.listSysFonts({ accessToken });
        const sysFonts = yield sysFontsReq.json();
        const fontNames = [
          ...new Set(
            resultBody.data.reduce((accumulator, template) => {
              accumulator.push(...template.texts.map((t) => t.font));
              return accumulator;
            }, [])
          ),
        ];
        const fonts = fontNames.map((name) => sysFonts.data.find((f) => f.name === name)).filter((f) => f);
        const fontsLoaded = yield loadFonts(fonts);
        yield put(actionCreator.listPrintTemplatesSuccess(resultBody.data));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listPrintTemplatesFailure(error));
    }
  }

  *deletePrintTemplates({ ids, accessToken }) {
    try {
      yield put(actionCreator.deletePrintTemplatesRequest());
      const result = yield this.backend.deletePrintTemplates({ ids, accessToken });
      const resultBody = yield result.json();
      if (resultBody && resultBody.data) {
        yield put(actionCreator.deletePrintTemplatesSuccess(ids));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.deletePrintTemplatesFailure(error));
    }
  }

  *listSysTemplates({ templateType, accessToken }) {
    try {
      yield put(actionCreator.listSysTemplatesRequest());
      const result = yield this.backend.listSysTemplates({ templateType, accessToken });
      const resultBody = yield result.json();
      if (resultBody && resultBody.data) {
        yield put(actionCreator.listSysTemplatesSuccess(resultBody.data));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listSysTemplatesFailure(error));
    }
  }

  *downloadSysTemplate({ key, accessToken }) {
    if (key && accessToken) {
      try {
        yield put(actionCreator.downloadSysTemplateRequest());
        const result = yield this.backend.downloadSysTemplate({ key, accessToken });
        const resultBody = yield result.json();
        if (result.status === 200 && resultBody && resultBody.data) {
          yield put(actionCreator.downloadSysTemplateSuccess(resultBody.data));
        } else {
          throw new Error('Invalid server response');
        }
      } catch (error) {
        yield put(actionCreator.downloadSysTemplateFailure());
      }
    }
  }

  *listSysFonts({ accessToken }) {
    try {
      yield put(actionCreator.listSysFontsRequest());
      const result = yield this.backend.listSysFonts({ accessToken });
      const resultBody = yield result.json();
      if (resultBody && resultBody.data) {
        yield put(actionCreator.listSysFontsSuccess(resultBody.data));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listSysFontsFailure(error));
    }
  }

  *listPrintTemplateLayouts({ accessToken }) {
    if (accessToken) {
      try {
        yield put(actionCreator.listPrintTemplateLayoutsRequest());
        const result = yield this.backend.listPrintTemplateLayouts({ accessToken });
        const resultBody = yield result.json();
        if (result.status === 200 && resultBody && resultBody.data) {
          yield put(actionCreator.listPrintTemplateLayoutsSuccess(resultBody.data));
        } else {
          throw new Error('Invalid server response');
        }
      } catch (error) {
        yield put(actionCreator.listPrintTemplateLayoutsFailure());
      }
    }
  }

  *listPoseTips() {
    try {
      yield put(actionCreator.listPoseTipsRequest());
      const result = yield this.backend.listPoseTips();
      const resultBody = yield result.json();
      if (result.status === 200 && resultBody && resultBody.data) {
        yield put(actionCreator.listPoseTipsSuccess(resultBody.data));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listPoseTipsFailure(error));
    }
  }

  *getAppUserStats({ accessToken }) {
    try {
      yield put(actionCreator.getAppUserStatsRequest());
      const result = yield this.backend.getAppUserStats({ accessToken });
      const resultBody = yield result.json();
      if (result.status === 200 && resultBody && resultBody.data) {
        yield put(actionCreator.getAppUserStatsSuccess(resultBody.data));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.getAppUserStatsFailure(error));
    }
  }

  *listPortfolioEvents({ data, level, id }) {
    yield put(actionCreator.listPortfolioEventsRequest());
    try {
      const result = yield this.backend.listPortfolioEvents({ data, level, id });
      const body = yield result.json();
      if (result.status === 200 && body.data) {
        yield put(actionCreator.listPortfolioEventsSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.listPortfolioEventsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.listPortfolioEventsFailure(error.message));
    }
  }

  *getPortfolioSettings({ id, passwordAccessToken }) {
    yield put(actionCreator.getPortfolioSettingsRequest());
    try {
      const result = yield this.backend.getPortfolioSettings({ id, passwordAccessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.getPortfolioSettingsSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.getPortfolioSettingsFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.getPortfolioSettingsFailure(error));
    }
  }

  *checkForMedia({ sourceId }) {
    yield put(actionCreator.checkForMediaRequest());
    try {
      const result = yield this.backend.checkForMedia({ sourceId });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.checkForMediaSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.checkForMediaFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.checkForMediaFailure(error));
    }
  }

  *submitPendingShare({ sourceId, target, shareType }) {
    yield put(actionCreator.submitPendingShareRequest());
    try {
      const result = yield this.backend.submitPendingShare({ sourceId, target, shareType });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.submitPendingShareSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.submitPendingShareFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.submitPendingShareFailure(error));
    }
  }

  *updateUserPlan({ data, accessToken }) {
    try {
      yield put(actionCreator.updateUserPlanRequest());
      const result = yield this.backend.updateUserPlan({ plan: data, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.updateUserPlanSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.updateUserPlanFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.updateUserPlanFailure(error));
    }
  }

  *subscribe({ data, accessToken }) {
    try {
      yield put(actionCreator.subscribeRequest());
      const result = yield this.backend.subscribe({ plan: data, accessToken });
      const body = yield result.json();
      if (body.data) {
        // Set newUser to false in operator cookie to prevent
        // redirecting to "Welcome" page after a payment method
        // has been added
        const operatorCookie = Cookies.get('operator');
        if (operatorCookie && operatorCookie.newUser) {
          Cookies.set('operator', { ...operatorCookie, newUser: false });
        }
        yield put(actionCreator.subscribeSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.subscribeFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.subscribeFailure(error));
    }
  }

  *cancelPlanSchedule({ accessToken }) {
    try {
      yield put(actionCreator.updateUserPlanRequest());
      const result = yield this.backend.cancelPlanSchedule({ accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.cancelPlanScheduleSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.cancelPlanScheduleFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.cancelPlanScheduleFailure(error));
    }
  }

  *verifyEmail({ token }) {
    try {
      yield put(actionCreator.verifyEmailRequest());
      const result = yield this.backend.verifyEmail({ token });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.verifyEmailSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.verifyEmailFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.verifyEmailFailure(error));
    }
  }

  *resendVerification({ email }) {
    try {
      yield put(actionCreator.resendVerificationRequest());
      const result = yield this.backend.resendVerification(email);
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.resendVerificationSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.resendVerificationFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.resendVerificationFailure(error));
    }
  }

  *uploadMedia({ source, eventId, captureMode, frameRate }) {
    yield put(actionCreator.uploadMediaRequest());
    try {
      const result = yield this.backend.uploadMedia({ source, eventId, captureMode, frameRate });
      const body = yield result.json();
      const mediaId = _.get(body, 'mediaId');
      if (mediaId) {
        yield put(actionCreator.uploadMediaSuccess(mediaId));
      } else if (body.error) {
        yield put(actionCreator.uploadMediaFailure(body.error));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.uploadMediaFailure(error.message));
    }
  }

  *processMedia({ source, eventId, settings }) {
    yield put(actionCreator.processMediaRequest());
    try {
      const src = yield this.backend.processMedia({ source, eventId, settings });
      yield put(actionCreator.processMediaSuccess(src));
    } catch (error) {
      yield put(actionCreator.processMediaFailure(error.message));
    }
  }

  *getVirtualBooth({ eventId }) {
    yield put(actionCreator.virtualBoothRequest());
    try {
      const result = yield this.backend.getVirtualBooth({ eventId });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.virtualBoothSuccess(body.data));
      } else if (body.errors) {
        yield put(actionCreator.virtualBoothFailure(body.errors[0].message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(actionCreator.virtualBoothFailure(error.message));
    }
  }

  *getShopifyCustomerEmail({ queryStr, accessToken }) {
    yield put(marketingActions.getShopifyCustomerEmailRequest());
    try {
      const result = yield this.backend.getShopifyCustomerEmail({ queryStr, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(marketingActions.getShopifyCustomerEmailSuccess(body.data.shopifyCustomerEmail));
      } else if (body.errors) {
        throw new Error(body.errors.map((e) => e.message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(marketingActions.getShopifyCustomerEmailFailure(error.message));
    }
  }

  *getMarketingMaterials({ accessToken }) {
    yield put(marketingActions.getMarketingMaterialsRequest());
    try {
      const result = yield this.backend.getMarketingMaterials({ accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(marketingActions.getMarketingMaterialsSuccess(body.data));
      } else if (body.errors) {
        throw new Error(body.errors.map((e) => e.message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(marketingActions.getMarketingMaterialsFailure(error.message));
    }
  }

  *downloadMarketingMaterial({ key, accessToken }) {
    yield put(marketingActions.downloadMarketingMaterialRequest());
    try {
      const result = yield this.backend.downloadMarketingMaterial({ key, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(marketingActions.downloadMarketingMaterialSuccess(body.data.url));
      } else if (body.errors) {
        throw new Error(body.errors.map((e) => e.message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(marketingActions.downloadMarketingMaterialFailure(error.message));
    }
  }

  *getProposalData({ eventId }) {
    try {
      const result = yield this.backend.getProposalData({ eventId });
      const body = yield result.json();
      if (body.data) {
        yield put(proposalActions.getProposalDataSuccess(body.data));
      } else if (body.errors) {
        throw new Error(body.errors.map((e) => e.message));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      yield put(proposalActions.getProposalDataFailure(error.message));
    }
  }

  *superUserLogin({ email, password }) {
    yield put(actionCreator.superUserLoginRequest());
    try {
      const result = yield this.backend.superUserLogin({ email, password });
      const body = yield result.json();
      if (_.get(body, 'data.accessToken')) {
        yield put(
          actionCreator.superUserLoginSuccess({
            token: body.data.accessToken,
            email,
          })
        );
      } else if (body.error) {
        throw new Error(body.error);
      } else {
        throw new Error('An unexpected error occurred');
      }
    } catch (error) {
      yield put(actionCreator.superUserLoginFailure(error.message));
    }
  }

  *superUserGetAccess({ email, accessToken }) {
    yield put(actionCreator.superUserGetAccessRequest());
    try {
      const result = yield this.backend.superUserGetAccess({ email, token: accessToken });
      const body = yield result.json();
      if (body.data) {
        // Remove existing operator data if any
        utils.removeFromLocalStorage('token');
        Cookies.remove('operator');
        yield put(actionCreator.superUserGetAccessSuccess(body.data));
      } else if (body.error) {
        throw new Error(body.error);
      } else {
        throw new Error('An unexpected error occurred');
      }
    } catch (error) {
      yield put(actionCreator.superUserGetAccessFailure(error.message));
    }
  }

  *superUserGetOperatorToken({ email, loginToken }) {
    yield put(actionCreator.superUserGetOperatorTokenRequest());
    try {
      const tokenResult = yield this.backend.superUserGetOperatorToken({ loginToken });
      const tokenBody = yield tokenResult.json();
      if (_.get(tokenBody, 'data.token')) {
        // With the accessToken, we can fetch the remaining user info
        yield put(actionCreator.fetchProfileRequest(false));
        const profile = yield this.backend.requestProfile({ accessToken: tokenBody.data.token });
        yield put(actionCreator.fetchProfileSuccess(profile, false));

        // Add operator object to Cookies/localStorage
        // to bypass regular logIn function
        const loggedUser = {
          email,
          token: tokenBody.data.token,
          accessToken: tokenBody.data.token,
          organizationId: profile.organizationId,
          operatorId: profile.ownerId,
          ownerId: profile.ownerId,
          newUser: false,
          superUser: true,
        };
        utils.saveToLocalStorage('token', loggedUser);
        Cookies.set('operator', loggedUser, {
          expires: moment().add(1, 'day').toDate(),
        });

        // After "login", finally return success
        yield put(actionCreator.superUserGetOperatorTokenSuccess(loggedUser));
      } else if (tokenBody.error) {
        throw new Error(tokenBody.error);
      } else {
        throw new Error('An unexpected error occurred');
      }
    } catch (error) {
      yield put(actionCreator.superUserGetOperatorTokenFailure(error.message));
    }
  }

  *superUserGetOperator({ email, accessToken }) {
    yield put(actionCreator.superUserGetOperatorRequest());
    try {
      const result = yield this.backend.superUserGetOperator({ email, accessToken });
      const body = yield result.json();
      if (body.data) {
        yield put(actionCreator.superUserGetOperatorSuccess(body.data));
      } else if (body.error) {
        throw new Error(body.error);
      } else {
        throw new Error('An unexpected error occurred');
      }
    } catch (error) {
      yield put(actionCreator.superUserGetOperatorFailure(error.message));
    }
  }

  *superUserUpdateOperator({ operatorId, body, accessToken }) {
    yield put(actionCreator.superUserUpdateOperatorRequest());
    try {
      const result = yield this.backend.superUserUpdateOperator({ operatorId, body, accessToken });
      const resultBody = yield result.json();
      if (resultBody.data) {
        yield put(actionCreator.superUserUpdateOperatorSuccess(resultBody.data));
      } else if (resultBody.error) {
        throw new Error(resultBody.error);
      } else {
        throw new Error('An unexpected error occurred');
      }
    } catch (error) {
      yield put(actionCreator.superUserUpdateOperatorFailure(error.message));
    }
  }

  *uploadCaptureToS3({ eventId, file, captureMode, accessToken }) {
    yield put(actionCreator.uploadCaptureToS3Request());
    try {
      // while (true) {
      // Validate file
      if (!['image/jpeg', 'video/mp4'].includes(file.type)) {
        throw new Error('One of the files could not be uploaded. Files must be a .jpg or .mp4.');
      }
      if (file.size > 50000000) {
        throw new Error('One of the files could not be uploaded. Files must be smaller than 50MB.');
      }

      // Get form data from S3 for a presigned upload
      const s3Data = yield this.backend.getS3FormData({ eventId, contentType: file.type, accessToken });
      const s3Body = yield s3Data.json();
      if (s3Body.data && s3Body.data.url && s3Body.data.fields) {
        // Add form data to request
        const formData = new FormData();
        Object.entries(s3Body.data.fields).forEach(([field, value]) => {
          formData.append(field, value);
        });
        // Add the actual file to the request
        formData.append('file', file);
        // Upload the file to S3
        const response = yield fetch(s3Body.data.url, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        // File is now on S3, time to add the capture to the db
        const saveMedia = yield this.backend.saveCapture({
          eventId,
          accessToken,
          captureMode,
          filename: s3Body.data.fields.key,
          captureTime: (file.lastModifiedDate || new Date()).toISOString(),
          uploadSource: 'directUpload',
        });
        const mediaBody = yield saveMedia.json();
        if (mediaBody.mediaId) {
          yield put(actionCreator.uploadCaptureToS3Success(mediaBody));
        } else if (mediaBody.error) {
          throw new Error(mediaBody.error);
        } else {
          throw new Error('Saving capture failed');
        }
      } else if (s3Body.error) {
        throw new Error(s3Body.error);
      } else {
        throw new Error('S3 form data request failed');
      }
      // }
    } catch (error) {
      yield put(actionCreator.uploadCaptureToS3Failure(error.message));
    } finally {
      /* if (yield cancelled()) {
          yield put(actionCreator.uploadCaptureToS3Cancelled();
        } */
    }
  }

  *listCountries() {
    yield put(listCountriesRequest());
    try {
      const res = yield this.backend.listCountries();
      const body = yield res.json();
      if (body.data?.countries) {
        yield put(listCountriesSuccess(body.data.countries));
      } else if (body.error) {
        throw new Error(body.error);
      } else {
        throw new Error('Invalid server response');
      }
    } catch (err) {
      yield put(listCountriesFailure(err.message));
    }
  }

  *watchSharePhoto() {
    yield takeEvery(actionCreator.SHARE_PHOTO, this.sharePhotoByEmail);
  }

  *watchRequestEvent() {
    yield takeEvery(actionCreator.REQUEST_GET_EVENT, this.requestEvent);
  }

  *watchRequestEventAnalytics() {
    yield takeEvery(actionCreator.REQUEST_GET_EVENT_ANALYTICS, this.requestEventAnalytics);
  }

  *watchRequestEventTopMedias() {
    yield takeEvery(actionCreator.REQUEST_GET_EVENT_TOP_MEDIAS, this.requestEventTopMedias);
  }

  *watchRequestMedia() {
    yield takeEvery(actionCreator.REQUEST_GET_MEDIA, this.requestMedia);
  }

  *watchRequestMedias() {
    yield takeEvery(actionCreator.REQUEST_GET_MEDIAS, this.requestMedias);
  }

  *watchRequestPlans() {
    yield takeEvery(actionCreator.FETCH_PLANS, this.requestPlans);
  }

  *watchRequestDevices() {
    yield takeEvery(actionCreator.FETCH_DEVICES, this.requestDevices);
  }

  *watchRequestRemoveDevice() {
    yield takeEvery(actionCreator.REMOVE_DEVICE, this.requestRemoveDevice);
  }

  *watchRequestProfile() {
    yield takeEvery(actionCreator.FETCH_PROFILE, this.requestProfile);
  }

  *watchRequestInvoices() {
    yield takeEvery(actionCreator.FETCH_INVOICES, this.requestInvoices);
  }

  *watchUpdateOrganization() {
    yield takeEvery(actionCreator.UPDATE_ORGANIZATION, this.updateOrganization);
  }

  *watchResetOrganizationStore() {
    yield takeLatest(actionCreator.RESET_ORGANIZATION_UPDATE, this.resetOrganizationStore);
  }

  *watchSignUp() {
    yield takeLatest(actionCreator.SIGN_UP, this.signUp);
  }

  *watchLogIn() {
    yield takeLatest(actionCreator.LOG_IN, this.logIn);
  }

  *watchresetPasswordInitiate() {
    yield takeLatest(actionCreator.RESET_PASSWORD_INITIATE, this.resetPasswordInitiate);
  }

  *watchresetPasswordValidate() {
    yield takeLatest(actionCreator.RESET_PASSWORD_VALIDATE, this.resetPasswordValidate);
  }

  *watchresetPasswordFinally() {
    yield takeLatest(actionCreator.RESET_PASSWORD_FINALLY, this.resetPasswordFinally);
  }

  *watchStoreCheckoutData() {
    yield takeLatest(actionCreator.STORE_CHECKOUT_DATA, this.storeCheckoutData);
  }

  *watchRequestPricing() {
    yield takeLatest(actionCreator.PRICING, this.requestPricing);
  }

  *watchEventNeedsPassword() {
    yield takeLatest(actionCreator.EVENT_NEEDS_PASSWORD, this.requirePassword);
  }

  *watchPasswordTokenRequest() {
    yield takeLatest(actionCreator.REQUEST_EVENT_WITH_PASSWORD, this.passwordToken);
  }

  *watchIncrementShareClicks() {
    yield takeLatest(actionCreator.INCREMENT_SHARE_CLICKS, this.incrementShareClicks);
  }

  *watchSetBackendUrl() {
    yield takeLatest(actionCreator.SET_BACKEND_URL, this.setBackendUrl);
  }

  *watchGetEvent() {
    yield takeLatest(actionCreator.GET_EVENT, this.getEvent);
  }

  *watchGetSseEvent() {
    yield takeLatest(actionCreator.GET_SSE_EVENT, this.getSseEvent);
  }

  *watchCreateEvent() {
    yield takeLatest(actionCreator.CREATE_EVENT, this.createEvent);
  }

  *watchUpdateEvent() {
    yield takeLatest(actionCreator.UPDATE_EVENT, this.updateEvent);
  }

  *watchDuplicateEvent() {
    yield takeLatest(actionCreator.DUPLICATE_EVENT, this.duplicateEvent);
  }

  *watchDeleteEvents() {
    yield takeLatest(actionCreator.DELETE_EVENTS, this.deleteEvents);
  }

  *watchListEvents() {
    yield takeLatest(actionCreator.LIST_EVENTS, this.listEvents);
  }

  *watchListSampleEvents() {
    yield takeLatest(actionCreator.LIST_SAMPLE_EVENTS, this.listSampleEvents);
  }

  *watchListMedias() {
    yield takeLatest(actionCreator.LIST_MEDIAS, this.listMedias);
  }

  *watchDeleteMedias() {
    yield takeLatest(actionCreator.DELETE_MEDIAS, this.deleteMedias);
  }

  *watchUploadAsset() {
    yield takeEvery(actionCreator.UPLOAD_ASSET, this.uploadAsset);
  }

  *watchListAssets() {
    yield takeLatest(actionCreator.LIST_ASSETS, this.listAssets);
  }

  *watchLoadSysAssets() {
    yield takeLatest(actionCreator.LOAD_SYS_ASSETS, this.loadSysAssets);
  }

  *watchDeleteAssets() {
    yield takeLatest(actionCreator.DELETE_ASSETS, this.deleteAssets);
  }

  *watchEmailLinks() {
    yield takeLatest(actionCreator.EMAIL_LINKS, this.emailLinks);
  }

  *watchEmailCancellation() {
    yield takeLatest(actionCreator.EMAIL_CANCELLATION, this.emailCancellation);
  }

  *watchAddSendingDomain() {
    yield takeLatest(actionCreator.ADD_SENDING_DOMAIN, this.addSendingDomain);
  }

  *watchDeleteSendingDomain() {
    yield takeLatest(actionCreator.DELETE_SENDING_DOMAIN, this.deleteSendingDomain);
  }

  *watchUpdateOperator() {
    yield takeLatest(actionCreator.UPDATE_OPERATOR, this.updateOperator);
  }

  *watchUpdateAcademyVideoProgress() {
    yield takeLatest(actionCreator.UPDATE_ACADEMY_VIDEO_PROGRESS, this.updateAcademyVideoProgress);
  }

  *watchGetSseProfile() {
    yield takeLatest(actionCreator.GET_SSE_PROFILE, this.getSseProfile);
  }

  *watchGetPrintTemplate() {
    yield takeEvery(actionCreator.GET_PRINT_TEMPLATE, this.getPrintTemplate);
  }

  *watchCreatePrintTemplate() {
    yield takeLatest(actionCreator.CREATE_PRINT_TEMPLATE, this.createPrintTemplate);
  }

  *watchUpdatePrintTemplate() {
    yield takeLatest(actionCreator.UPDATE_PRINT_TEMPLATE, this.updatePrintTemplate);
  }

  *watchListPrintTemplates() {
    yield takeLatest(actionCreator.LIST_PRINT_TEMPLATES, this.listPrintTemplates);
  }

  *watchDeletePrintTemplates() {
    yield takeLatest(actionCreator.DELETE_PRINT_TEMPLATES, this.deletePrintTemplates);
  }

  *watchListSysTemplates() {
    yield takeLatest(actionCreator.LIST_SYS_TEMPLATES, this.listSysTemplates);
  }

  *watchDownloadSysTemplate() {
    yield takeLatest(actionCreator.DOWNLOAD_SYS_TEMPLATE, this.downloadSysTemplate);
  }

  *watchListSysFonts() {
    yield takeLatest(actionCreator.LIST_SYS_FONTS, this.listSysFonts);
  }

  *watchListPrintTemplateLayouts() {
    yield takeLatest(actionCreator.LIST_PRINT_TEMPLATE_LAYOUTS, this.listPrintTemplateLayouts);
  }

  *watchListPoseTips() {
    yield takeLatest(actionCreator.LIST_POSE_TIPS, this.listPoseTips);
  }

  *watchGetAppUserStats() {
    yield takeLatest(actionCreator.GET_APP_USER_STATS, this.getAppUserStats);
  }

  *watchListPortfolioEvents() {
    yield takeLatest(actionCreator.LIST_PORTFOLIO_EVENTS, this.listPortfolioEvents);
  }

  *watchGetPortfolioSettings() {
    yield takeLatest(actionCreator.GET_PORTFOLIO_SETTINGS, this.getPortfolioSettings);
  }

  *watchUpdateUserPlan() {
    yield takeLatest(actionCreator.UPDATE_USER_PLAN, this.updateUserPlan);
  }

  *watchSubscribe() {
    yield takeLatest(actionCreator.SUBSCRIBE, this.subscribe);
  }

  *watchCancelPlanSchedule() {
    yield takeLatest(actionCreator.CANCEL_PLAN_SCHEDULE, this.cancelPlanSchedule);
  }

  *watchVerifyEmail() {
    yield takeLatest(actionCreator.VERIFY_EMAIL, this.verifyEmail);
  }

  *watchResendVerification() {
    yield takeLatest(actionCreator.RESEND_VERIFICATION, this.resendVerification);
  }

  *watchCheckForMedia() {
    yield takeLatest(actionCreator.CHECK_FOR_MEDIA, this.checkForMedia);
  }

  *watchSubmitPendingShare() {
    yield takeLatest(actionCreator.SUBMIT_PENDING_SHARE, this.submitPendingShare);
  }

  *watchUploadMedia() {
    yield takeLatest(actionCreator.UPLOAD_MEDIA, this.uploadMedia);
  }

  *watchProcessMedia() {
    yield takeLatest(actionCreator.PROCESS_MEDIA, this.processMedia);
  }

  *watchGetVirtualBooth() {
    yield takeLatest(actionCreator.VIRTUAL_BOOTH, this.getVirtualBooth);
  }

  *watchUploadCaptureToS3() {
    yield takeLatest(actionCreator.UPLOAD_CAPTURE_TO_S3, this.uploadCaptureToS3);
  }

  *watchGetShopifyCustomerEmail() {
    yield takeLatest(marketingActions.GET_SHOPIFY_CUSTOMER_EMAIL, this.getShopifyCustomerEmail);
  }

  *watchGetMarketingMaterials() {
    yield takeLatest(marketingActions.GET_MARKETING_MATERIALS, this.getMarketingMaterials);
  }

  *watchDownloadMarketingMaterial() {
    yield takeLatest(marketingActions.DOWNLOAD_MARKETING_MATERIAL, this.downloadMarketingMaterial);
  }

  *watchGetProposalData() {
    yield takeLatest(proposalActions.GET_PROPOSAL_DATA, this.getProposalData);
  }

  *watchSuperUserLogin() {
    yield takeEvery(actionCreator.SUPERUSER_LOGIN, this.superUserLogin);
  }

  *watchSuperUserGetAccess() {
    yield takeLatest(actionCreator.SUPERUSER_GET_ACCESS, this.superUserGetAccess);
  }

  *watchSuperUserGetOperatorToken() {
    yield takeLatest(actionCreator.SUPERUSER_GET_OPERATOR_TOKEN, this.superUserGetOperatorToken);
  }

  *watchSuperUserGetOperator() {
    yield takeLatest(actionCreator.SUPERUSER_GET_OPERATOR, this.superUserGetOperator);
  }

  *watchSuperUserUpdateOperator() {
    yield takeLatest(actionCreator.SUPERUSER_UPDATE_OPERATOR, this.superUserUpdateOperator);
  }

  *watchListCountries() {
    yield takeLatest(LIST_COUNTRIES, this.listCountries);
  }

  *rootSaga() {
    yield all([
      this.watchSharePhoto(),
      this.watchRequestEvent(),
      this.watchRequestEventAnalytics(),
      this.watchRequestEventTopMedias(),
      this.watchRequestMedia(),
      this.watchRequestMedias(),
      this.watchRequestPlans(),
      this.watchRequestDevices(),
      this.watchRequestRemoveDevice(),
      this.watchRequestProfile(),
      this.watchRequestInvoices(),
      this.watchUpdateOrganization(),
      this.watchSignUp(),
      this.watchLogIn(),
      this.watchresetPasswordInitiate(),
      this.watchresetPasswordValidate(),
      this.watchresetPasswordFinally(),
      this.watchResetOrganizationStore(),
      this.watchStoreCheckoutData(),
      this.watchRequestPricing(),
      this.watchEventNeedsPassword(),
      this.watchPasswordTokenRequest(),
      this.watchIncrementShareClicks(),
      this.watchSetBackendUrl(),
      this.watchGetEvent(),
      this.watchGetSseEvent(),
      this.watchCreateEvent(),
      this.watchUpdateEvent(),
      this.watchDuplicateEvent(),
      this.watchDeleteEvents(),
      this.watchListEvents(),
      this.watchListSampleEvents(),
      this.watchListMedias(),
      this.watchDeleteMedias(),
      this.watchUploadAsset(),
      this.watchListAssets(),
      this.watchLoadSysAssets(),
      this.watchDeleteAssets(),
      this.watchEmailLinks(),
      this.watchEmailCancellation(),
      this.watchAddSendingDomain(),
      this.watchDeleteSendingDomain(),
      this.watchUpdateOperator(),
      this.watchUpdateAcademyVideoProgress(),
      this.watchGetSseProfile(),
      this.watchGetPrintTemplate(),
      this.watchCreatePrintTemplate(),
      this.watchUpdatePrintTemplate(),
      this.watchListPrintTemplates(),
      this.watchDeletePrintTemplates(),
      this.watchListSysTemplates(),
      this.watchDownloadSysTemplate(),
      this.watchListSysFonts(),
      this.watchListPrintTemplateLayouts(),
      this.watchListPoseTips(),
      this.watchGetAppUserStats(),
      this.watchListPortfolioEvents(),
      this.watchGetPortfolioSettings(),
      this.watchCheckForMedia(),
      this.watchSubmitPendingShare(),
      this.watchUpdateUserPlan(),
      this.watchSubscribe(),
      this.watchCancelPlanSchedule(),
      this.watchVerifyEmail(),
      this.watchResendVerification(),
      this.watchUploadMedia(),
      this.watchProcessMedia(),
      this.watchUploadCaptureToS3(),
      this.watchGetVirtualBooth(),
      this.watchGetShopifyCustomerEmail(),
      this.watchGetMarketingMaterials(),
      this.watchDownloadMarketingMaterial(),
      this.watchGetProposalData(),
      this.watchSuperUserLogin(),
      this.watchSuperUserGetAccess(),
      this.watchSuperUserGetOperator(),
      this.watchSuperUserGetOperatorToken(),
      this.watchSuperUserUpdateOperator(),
      this.watchListCountries(),
    ]);
  }

  _createBackendFilterString(options, medias, apiName) {
    let dateFrom = '';
    let dateTo = '';
    let captureMode = '';
    let order = '';

    const filters = {
      captureMode: null,
      dateFrom: null,
      dateTo: null,
      filterByCaptureMode: false,
      filterByTime: false,
      isMobile: false,
      order: 'DESC',
      ...options,
    };

    const dates = _.map(medias, 'date');

    if (filters.order === 'ASC') {
      if (dates && dates.length) {
        dateFrom = _.max(dates);
      } else if (filters.dateFrom) {
        const date = filters.dateFrom.format();
        dateFrom = date;
      }

      if (filters.dateTo) {
        const date = filters.dateTo.format();
        dateTo = date;
      }
      order = 'ASC';
    } else {
      if (filters.dateFrom) {
        const date = filters.dateFrom.format();
        dateFrom = date;
      }
      if (dates && dates.length) {
        dateTo = _.min(dates);
      } else if (filters.dateTo) {
        const date = filters.dateTo.format();
        dateTo = date;
      }
      order = 'DESC';
    }

    if (filters.captureMode) {
      captureMode = filters.captureMode;
    }

    // Queso and Salsa require different formats for the filter query
    let filterString = '';
    if (apiName === 'salsa') {
      filterString = '{';
      if (dateFrom) {
        filterString += `dateFrom:"${dateFrom}",`;
      }
      if (dateTo) {
        filterString += `dateTo:"${dateTo}",`;
      }
      if (order) {
        filterString += `order:"${order}",`;
      }
      if (captureMode) {
        filterString += `captureMode:"${captureMode}",`;
      }
      filterString += '}';
    } else if (apiName === 'queso') {
      if (dateFrom) {
        filterString += `dateFrom=${dateFrom}&`;
      }
      if (dateTo) {
        filterString += `dateTo=${dateTo}&`;
      }
      if (order) {
        filterString += `order=${order}&`;
      }
      if (captureMode) {
        filterString += `captureMode=${captureMode}&`;
      }

      // Remove last '&'
      filterString = filterString.slice(0, -1);
    }

    return filterString;
  }
}

export default Sagas;
