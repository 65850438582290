export const EVENT_SETTING_GROUPS = {
  info: {
    title: 'Event Info',
    settings: ['title', 'eventDate', 'endDate', 'location', 'passcodeEnabled', 'portfolioEnabled'],
  },
  interface: {
    title: 'Event Interface',
    description: 'This will be the first point of contact with your guests.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/event-interface',
    supportText: 'Learn how to configure it.',
    settings: [
      'captureConfig.countdownTime',
      'boothBackgroundUrl',
      'captureConfig.handsFreeConfiguration.isEnabled',
      'startScreenUrl',
      'disclaimerEnabled',
      'disclaimerTitle',
      'disclaimerBody',
    ],
  },
  led: {
    title: 'LED Ring',
    description:
      'You can customize the color, brightness, and movement pattern of your photo booth’s LED ring for each event.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/customize-led-ring',
    supportText: 'Learn how to customize the Salsa LEDs.',
    settings: [
      'ledBrandingColor',
      'ledBrandingAnimation',
      'ledWhiteBrightness',
      'ledRGBBrightness',
      'ledPatternModeEnabled',
    ],
  },
  liveGallery: {
    title: 'Live Gallery',
    description:
      'The Live Gallery is a branded website, updated in real time, where users can view, share, and download media from your event. If you disable it, guests will still be able to view their individual captures.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/live-gallery',
    supportText: 'Learn how to set up the Live Gallery.',
    settings: [
      'fullGalleryEnabled',
      'micrositeAccentColor',
      'password',
      'passwordHint',
      'passwordProtected',
      'logoUrl',
      'logoLink',
      'subtitle',
      'backgroundUrl',
      'buttonLink',
      'buttonText',
      'buttonFlag',
      'liveGallery',
    ],
  },
  virtualBooth: {
    title: 'Virtual Booth',
    description:
      'Virtual Booths allow anyone - on any device - to post customized photos, boomerangs, GIFs, and videos to your Live Gallery.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/virtual-booth#customize-virtual-booth',
    supportText: 'Learn how to customize your Virtual Booth.',
    rootSetting: 'virtualBoothEnabled',
    planRequired: 'pro',
    settings: ['virtualBoothEnabled', 'virtualBoothConfig'],
  },
  proposals: {
    title: 'Event Proposals',
    description: 'Close more deals and increase your value by using this proposal tool.',
    rootSetting: 'proposalEnabled',
    planRequired: 'pro',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/proposals',
    settings: ['proposalEnabled', 'proposalConfig'],
  },
  survey: {
    title: 'Survey',
    description:
      'With a survey, you can require your photo booth guests to answer one or more questions before sharing their capture(s).',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/survey',
    rootSetting: 'surveyEnabled',
    planRequired: 'pro',
    settings: ['surveyEnabled', 'surveyQuestions'],
  },
  captureModes: {
    title: 'Modes',
    description: '',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/creating-an-event#event-capture-settings',
    supportText: 'Learn about the different capture types and settings.',
    settings: [
      'captureConfig.modeConfiguration.photoConfiguration.isEnabled',
      'captureConfig.modeConfiguration.gifConfiguration.isEnabled',
      'captureConfig.modeConfiguration.gifConfiguration.frameInterval',
      'captureConfig.modeConfiguration.gifConfiguration.frameCount',
      'captureConfig.modeConfiguration.boomerangConfiguration.isEnabled',
      'captureConfig.modeConfiguration.boomerangConfiguration.unloopedDuration',
      'captureConfig.modeConfiguration.videoConfiguration.isEnabled',
      'captureConfig.modeConfiguration.videoConfiguration.duration',
      'captureConfig.modeConfiguration.videoConfiguration.audioEnabled',
      'captureConfig.modeConfiguration.galleryConfiguration.isEnabled',
      'sharingStationConfig.isEnabled',
    ],
  },
  filters: {
    title: 'Filters',
    description:
      'Selecting two or more filters will allow the photo booth guest to choose between them before the content is captured.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/filters',
    supportText: 'Learn more about Filters behavior.',
    settings: ['captureConfig.enabledFilters'],
  },
  masks: {
    title: 'Face Effects',
    description:
      'Selecting two or more masks will allow the photo booth guest to choose between them. Hover or tap-and-hold a mask to preview it.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/face-effects',
    planRequired: 'pro',
    settings: ['faceEffects.masks'],
    secondLine: 'Automatically apply face effect to all captures',
    tooltip:
      'When only one face effect is selected and the switch is ON, the selected mask will apply to all capture sessions by default',
    secondLineSetting: 'faceEffects.masks.autoApply',
  },
  overlays: {
    title: 'Overlays',
    description:
      'Enabling two or more overlays will allow the photo booth guest to choose between them before the content is captured.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/overlays',
    supportText: 'Learn where to find them and how to create your own customized Overlays.',
    settings: ['captureConfig.overlays'],
  },
  greenScreen: {
    title: 'Green Screen',
    description:
      'Enabling two or more green screen backgrounds will allow the photo booth guest to choose between them before the content is captured.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/green-screen',
    supportText: 'Learn how to set up a green screen.',
    planRequired: 'pro',
    settings: ['captureConfig.greenScreens', 'magicGreenScreenEnabled'],
  },
  poseTips: {
    title: 'Pose Tips',
    description: 'Give your guests pose ideas that appear before the capture countdown.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/pose-tips',
    supportText: 'Learn about Pose Tips.',
    rootSetting: 'captureConfig.poseTipsConfiguration.enabled',
    planRequired: 'pro',
    settings: ['captureConfig.poseTipsConfiguration'],
  },
  email: {
    title: 'Email Sharing',
    description: 'Give photo booth guests the ability to share their captures via email.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/email-sharing',
    rootSetting: 'email',
    settings: [
      'enabledSharingMethods',
      'shareSenderName',
      'shareEmailSubject',
      'publicOwnerEmail',
      'customSenderEmail',
      'emailTemplate',
    ],
  },
  sms: {
    title: 'Text Sharing',
    description: 'Give photo booth guests the ability to share their captures via SMS text.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/text',
    rootSetting: 'sms',
    settings: ['enabledSharingMethods', 'shareText', 'defaultSmsCountryCode'],
  },
  airDrop: {
    title: 'AirDrop',
    description: 'Give photo booth guests the ability to share their captures via AirDrop.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/airdrop',
    rootSetting: 'airdropEnabled',
    settings: ['airdropEnabled'],
  },
  airPrint: {
    title: 'AirPrint',
    description: 'Give photo booth guests the ability to print their captures via AirPrint.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/airprint',
    supportText: 'Learn how to set up AirPrint.',
    rootSetting: 'airprintEnabled',
    planRequired: 'pro',
    settings: ['airPrintEnabled'],
  },
  qrCode: {
    title: 'QR Code',
    description:
      'Give photo booth guests the ability to share their captures hands-free by scanning a QR code with their phone.',
    supportLink: 'https://support.photoboothsupplyco.com/salsa/qr-code',
    rootSetting: 'qrCodeEnabled',
    settings: ['qrCodeEnabled'],
  },
};

export const settingGroups = [
  {
    id: 'settings',
    title: 'Event Settings',
    pages: ['info', 'interface', 'led', 'liveGallery', 'virtualBooth', 'proposals', 'survey'],
  },
  {
    id: 'captureSettings',
    title: 'Capture Settings',
    pages: ['captureModes', 'filters', 'masks', 'overlays', 'greenScreen', 'poseTips'],
  },
  {
    id: 'sharing',
    title: 'Sharing',
    pages: ['email', 'sms', 'airDrop', 'airPrint', 'qrCode'],
  },
];

export const prettifyAssetCategory = (category) => {
  switch (category) {
    case 'greenScreenBackground':
      return 'Green Screen Background';
    case 'greenScreenOverlay':
      return 'Green Screen Overlay';
    case 'startScreen':
      return 'Tap to Start Screen';
    case 'boothBackgroundImage':
      return 'Background';
    case 'disclaimerScreen':
      return 'Disclaimer';
    case 'leadSectionLogo':
      return 'Logo';
    case 'liveGalleryFeaturedImage':
      return 'Featured Image';
    case 'printTemplateOverlay':
      return 'Print Template Overlay';
    case 'printTemplateBackground':
      return 'Print Template Background';
    case 'customButton':
      return 'Custom Button';
    case 'proposalBackDrop':
      return 'Backdrop';
    default:
      return 'Asset';
  }
};
