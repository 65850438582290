import _ from 'lodash';

class Backend {
  constructor(apis) {
    this.apis = apis;
  }

  getEvent({ eventId, token, apiName, subdomain }) {
    return this.apis[apiName].getEvent({ eventId, token, subdomain });
  }

  getEventAnalytics({ analyticsToken, eventId, apiName }) {
    if (apiName !== 'salsa') {
      return false;
    }
    return this.apis[apiName].getEventAnalytics({ analyticsToken, eventId });
  }

  getSurveyResults({ eventId, token, apiName, subdomain }) {
    if (apiName !== 'salsa') {
      return false;
    }
    return this.apis[apiName].getSurveyResults({ eventId, token, subdomain });
  }

  getMediasWithFilters({ eventId, eventMedias, apiName, subdomain, filters }) {
    return this.apis[apiName].getMediasWithFilters({ eventId, eventMedias, subdomain, filters });
  }

  getMediaIndex({ eventId, subdomain, filters, dateAfter, apiName }) {
    return this.apis[apiName].getMediaIndex({ eventId, subdomain, filters, dateAfter });
  }

  getMedias({ eventId, eventMedias, apiName, subdomain }) {
    return this.apis[apiName].getMedias({ eventId, eventMedias, subdomain });
  }

  getMediasByIds({ eventId, mediaIds, apiName, subdomain }) {
    return this.apis[apiName].getMediasByIds({ eventId, mediaIds, subdomain });
  }

  getMedia({ eventId, mediaId, apiName, subdomain }) {
    return this.apis[apiName].getMedia({ eventId, mediaId, subdomain });
  }

  getTemplate({ eventId, mediaId, apiName }) {
    return this.apis[apiName].getTemplate({ eventId, mediaId });
  }

  sharePhoto({ eventId, mediaId, email, apiName }) {
    return this.apis[apiName].sharePhoto({ eventId, mediaId, email });
  }

  requestPlans({ apiName, env }) {
    return this.apis[apiName].getPlans(env);
  }

  requestBilling({ organizationId, apiName }) {
    return this.apis[apiName].getBilling({ organizationId });
  }

  requestDevices({ accessToken, apiName }) {
    return this.apis[apiName].getDevices({ accessToken });
  }

  async requestProfile(options) {
    const operator = (await (await this.apis.salsa.getAppUserOperator(options)).json()).data;
    const organization = operator.organization;
    const operatorFields = [
      'company',
      'academyProgress',
      'portfolio',
      'newEmail',
      'onboarded',
      'shopifyCustomerEmail',
      'dropbox',
    ];
    return _.merge({}, organization, _.pick(operator, operatorFields));
  }

  requestInvoices({ organizationId, apiName }) {
    return this.apis[apiName].getInvoices({ organizationId });
  }

  removeDevice({ accessToken, deviceId, apiName }) {
    return this.apis[apiName].removeDevice({ accessToken, deviceId });
  }

  updateOrganization({ organizationId, requestBody, apiName, accessToken }) {
    return this.apis[apiName].updateOrganization({ organizationId, requestBody, accessToken });
  }

  transformEvent({ eventBody, apiName }) {
    return this.apis[apiName].transformEvent({ eventBody });
  }

  transformMedia({ mediaBody, apiName }) {
    return this.apis[apiName].transformMedia({ mediaBody });
  }

  transformMedias({ mediasBody, apiName }) {
    return this.apis[apiName].transformMedias({ mediasBody });
  }

  transformAnalytics({ analytics, analyticsToken, surveyRows, apiName }) {
    if (apiName !== 'salsa') {
      return false;
    }
    return this.apis[apiName].transformAnalytics({ analytics, analyticsToken, surveyRows });
  }

  signUp({ user, apiName }) {
    return this.apis[apiName].signUp({ user });
  }

  logIn({ user, apiName }) {
    return this.apis[apiName].logIn({ user });
  }

  resetPasswordInitiate({ email, apiName }) {
    return this.apis[apiName].resetPasswordInitiate({ email });
  }

  resetPasswordValidate({ token, apiName }) {
    return this.apis[apiName].resetPasswordValidate({ token });
  }

  resetPasswordFinally({ token, newPassword, apiName }) {
    return this.apis[apiName].resetPasswordFinally({ token, newPassword });
  }

  requestPricing(options) {
    return this.apis.salsa.getAppUserPricing(options);
  }

  passwordToken({ id, password, apiName, idType }) {
    return this.apis[apiName].passwordToken({ id, password, idType });
  }

  incrementShareClicks({ body, apiName }) {
    return this.apis[apiName].incrementShareClicks({ body });
  }

  transformPasswordToken({ tokenBody, apiName }) {
    return this.apis[apiName].transformPasswordToken({ tokenBody });
  }

  getAppEvent({ accessToken, eventId }) {
    return this.apis.salsa.getAppEvent({ accessToken, eventId });
  }

  createEvent({ accessToken, data }) {
    return this.apis.salsa.createEvent({ accessToken, data });
  }

  updateEvent({ accessToken, data, id }) {
    return this.apis.salsa.updateEvent({ accessToken, data, id });
  }

  duplicateEvent({ accessToken, id }) {
    return this.apis.salsa.duplicateEvent({ accessToken, id });
  }

  deleteEvents({ accessToken, data }) {
    return this.apis.salsa.deleteEvents({ accessToken, data });
  }

  listEvents({ accessToken, level, data }) {
    return this.apis.salsa.listEvents({ accessToken, level, data });
  }

  listMedias({ accessToken, eventId, data }) {
    return this.apis.salsa.listMedias({ accessToken, eventId, data });
  }

  deleteMedias({ accessToken, eventId, data }) {
    return this.apis.salsa.deleteMedias({ accessToken, eventId, data });
  }

  uploadAsset(options) {
    return this.apis.salsa.uploadAsset(options);
  }

  listAssets({ accessToken, data }) {
    return this.apis.salsa.listAssets({ accessToken, data });
  }

  loadSysAssets({ category }) {
    return this.apis.salsa.loadSysAssets({ category });
  }

  deleteAssets({ accessToken, data }) {
    return this.apis.salsa.deleteAssets({ accessToken, data });
  }

  emailLinks(options) {
    return this.apis.salsa.emailLinks(options);
  }

  emailCancellation(options) {
    return this.apis.salsa.emailCancellation(options);
  }

  addSendingDomain(options) {
    return this.apis.salsa.addSendingDomain(options);
  }

  deleteSendingDomain(options) {
    return this.apis.salsa.deleteSendingDomain(options);
  }

  updateOperator(options) {
    return this.apis.salsa.updateOperator(options);
  }

  updateAcademyVideoProgress(options) {
    return this.apis.salsa.updateAcademyVideoProgress(options);
  }

  getPrintTemplate(options) {
    return this.apis.salsa.getPrintTemplate(options);
  }

  createPrintTemplate(options) {
    return this.apis.salsa.createPrintTemplate(options);
  }

  listPrintTemplates(options) {
    return this.apis.salsa.listPrintTemplates(options);
  }

  deletePrintTemplates(options) {
    return this.apis.salsa.deletePrintTemplates(options);
  }

  listSysTemplates(options) {
    return this.apis.salsa.listSysTemplates(options);
  }

  downloadSysTemplate(options) {
    return this.apis.salsa.downloadSysTemplate(options);
  }

  listSysFonts(options) {
    return this.apis.salsa.listSysFonts(options);
  }

  listPrintTemplateLayouts(options) {
    return this.apis.salsa.listPrintTemplateLayouts(options);
  }

  listPoseTips(options) {
    return this.apis.salsa.listPoseTips(options);
  }

  updatePrintTemplate(options) {
    return this.apis.salsa.updatePrintTemplate(options);
  }

  getAppUserStats(options) {
    return this.apis.salsa.getAppUserStats(options);
  }

  listPortfolioEvents(options) {
    return this.apis.salsa.listPortfolioEvents(options);
  }

  getPortfolioSettings(options) {
    return this.apis.salsa.getPortfolioSettings(options);
  }

  updateUserPlan(options) {
    return this.apis.salsa.updateUserPlan(options);
  }

  pauseUserPlan(options) {
    return this.apis.salsa.pauseUserPlan(options);
  }

  subscribe(options) {
    return this.apis.salsa.subscribe(options);
  }

  cancelPlanSchedule(options) {
    return this.apis.salsa.cancelPlanSchedule(options);
  }

  verifyEmail(options) {
    return this.apis.salsa.verifyEmail(options);
  }

  resendVerification(email) {
    return this.apis.salsa.resendVerification(email);
  }

  uploadMedia(options) {
    return this.apis.salsa.uploadMedia(options);
  }

  processMedia(options) {
    return this.apis.salsa.processMedia(options);
  }

  getVirtualBooth(options) {
    return this.apis.salsa.getVirtualBooth(options);
  }

  updateEnvironments({ env }) {
    this.apis.forEach((api) => {
      api.updateEnvironment({ env });
    });
  }

  checkForMedia(options) {
    return this.apis.salsa.checkForMedia(options);
  }

  submitPendingShare(options) {
    return this.apis.salsa.submitPendingShare(options);
  }

  getS3FormData(options) {
    return this.apis.salsa.getS3FormData(options);
  }

  saveCapture(options) {
    return this.apis.salsa.saveCapture(options);
  }

  getShopifyCustomerEmail(options) {
    return this.apis.salsa.getShopifyCustomerEmail(options);
  }

  getMarketingMaterials(options) {
    return this.apis.salsa.getMarketingMaterials(options);
  }

  downloadMarketingMaterial(options) {
    return this.apis.salsa.downloadMarketingMaterial(options);
  }

  getProposalData(options) {
    return this.apis.salsa.getProposalData(options);
  }

  superUserLogin(user) {
    return this.apis.success.login(user);
  }

  superUserGetAccess(options) {
    return this.apis.success.getLoginToken(options);
  }

  superUserGetOperatorToken(options) {
    return this.apis.success.getOperatorToken(options);
  }

  superUserGetOperator(options) {
    return this.apis.success.getOperator(options);
  }

  superUserUpdateOperator(options) {
    return this.apis.success.updateOperator(options);
  }

  listCountries() {
    return this.apis.salsa.listCountries();
  }
}

export default Backend;
