import moment from 'moment';
import _ from 'lodash';

import { combineReducers } from 'redux';
import {
  ACTIVE_PHOTO,
  ACTIVE_EVENT,
  ACTIVE_MEDIA,
  PAGE_TYPE,
  RETRIEVED_GET_EVENT,
  RETRIEVED_GET_MEDIAS,
  RETRIEVED_GET_TEMPLATES,
  RETRIEVED_GET_EVENT_ANALYTICS,
  RETRIEVED_GET_EVENT_TOP_MEDIAS,
  START_FETCH,
  END_FETCH,
  UPDATE_EVENT_MEDIAS,
  UPDATE_NEW_MEDIAS,
  UPDATE_EVENT_HAS_MORE,
  MEDIA_NOT_FOUND,
  EVENT_NOT_FOUND,
  DOWNLOAD_LINK,
  SHARE_IMAGE,
  SHARE_PHOTO,
  SHARE_PHOTO_REQUEST,
  SHARE_PHOTO_SUCCESS,
  SHARE_PHOTO_FAILURE,
  LOADING_MEDIA,
  LOADING_MEDIAS,
  FETCH_PLANS,
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_REQUEST,
  FETCH_DEVICES,
  FETCH_DEVICES_FAILURE,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_REQUEST,
  REMOVE_DEVICE_REQUEST,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILURE,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  CURRENT_DEVICE_QUANTITY,
  CURRENT_PLAN,
  CHANGE_DEVICE_QUANTITY_REQUEST,
  CHANGE_DEVICE_QUANTITY_SUCCESS,
  CHANGE_DEVICE_QUANTITY_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_IN_INCORRECT,
  DEFAULT_ORGANIZATION_UPDATE,
  ACCOUNT_FETCH_REQUEST,
  ACCOUNT_FETCH_FINISHED,
  STORE_CHECKOUT_DATA_REQUEST,
  RESET_PASSWORD_INITIATE_REQUEST,
  RESET_PASSWORD_INITIATE_FAILURE,
  RESET_PASSWORD_INITIATE_SUCCESS,
  RESET_PASSWORD_VALIDATE_REQUEST,
  RESET_PASSWORD_VALIDATE_FAILURE,
  RESET_PASSWORD_VALIDATE_SUCCESS,
  RESET_PASSWORD_FINALLY_REQUEST,
  RESET_PASSWORD_FINALLY_FAILURE,
  RESET_PASSWORD_FINALLY_SUCCESS,
  PRICING_REQUEST,
  PRICING_FAILURE,
  PRICING_SUCCESS,
  EVENT_PASSWORD_REQUEST,
  PASSWORD_NOT_REQUIRED,
  PASSWORD_TOKEN_FAILURE,
  PASSWORD_TOKEN_SUCCESS,
  PASSWORD_TOKEN_REQUEST,
  LOADING_EVENT,
  FINISHED_EVENT,
  SET_MEDIA_FILTERS,
  CLEAR_MEDIA_FILTERS,
  CLEAR_MEDIAS,
  UPDATE_EVENT_CLEAR_MEDIAS,
  UPDATE_ENVIRONMENT,
  SET_OPERATOR,
  LIST_EVENTS,
  LIST_EVENTS_SUCCESS,
  LIST_EVENTS_REQUEST,
  LIST_EVENTS_FAILURE,
  LIST_EVENTS_RESET,
  LIST_SAMPLE_EVENTS_REQUEST,
  LIST_SAMPLE_EVENTS_SUCCESS,
  LIST_SAMPLE_EVENTS_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  DUPLICATE_EVENT_REQUEST,
  DUPLICATE_EVENT_SUCCESS,
  DUPLICATE_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  UPDATE_EVENT_SUCCESS,
  LIST_MEDIAS,
  LIST_MEDIAS_REQUEST,
  LIST_MEDIAS_SUCCESS,
  LIST_MEDIAS_FAILURE,
  LIST_MEDIAS_RESET,
  UPDATE_OPERATOR_SUCCESS,
  SET_EVENT_VIEW,
  GET_SSE_EVENT_REQUEST,
  GET_SSE_EVENT_SUCCESS,
  GET_SSE_EVENT_FAILURE,
  SET_EVENT_DATA,
  SET_PROFILE_VIEW,
  SET_PROFILE_DATA,
  GET_SSE_PROFILE_REQUEST,
  GET_SSE_PROFILE_SUCCESS,
  GET_SSE_PROFILE_FAILURE,
  LIST_ASSETS,
  LIST_ASSETS_REQUEST,
  LIST_ASSETS_SUCCESS,
  LIST_ASSETS_FAILURE,
  LIST_ASSETS_RESET,
  DELETE_ASSETS_REQUEST,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAILURE,
  LOAD_SYS_ASSETS,
  LOAD_SYS_ASSETS_REQUEST,
  LOAD_SYS_ASSETS_SUCCESS,
  LOAD_SYS_ASSETS_FAILURE,
  UPLOAD_ASSET_REQUEST,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_FAILURE,
  GET_PRINT_TEMPLATE_REQUEST,
  GET_PRINT_TEMPLATE_SUCCESS,
  GET_PRINT_TEMPLATE_FAILURE,
  UPDATE_PRINT_TEMPLATE_REQUEST,
  UPDATE_PRINT_TEMPLATE_SUCCESS,
  UPDATE_PRINT_TEMPLATE_FAILURE,
  CREATE_PRINT_TEMPLATE_REQUEST,
  CREATE_PRINT_TEMPLATE_SUCCESS,
  CREATE_PRINT_TEMPLATE_FAILURE,
  LIST_PRINT_TEMPLATES_REQUEST,
  LIST_PRINT_TEMPLATES_SUCCESS,
  LIST_PRINT_TEMPLATES_FAILURE,
  LIST_PRINT_TEMPLATES_RESET,
  DELETE_PRINT_TEMPLATES_SUCCESS,
  LIST_SYS_TEMPLATES_REQUEST,
  LIST_SYS_TEMPLATES_SUCCESS,
  LIST_SYS_TEMPLATES_FAILURE,
  LIST_SYS_FONTS_REQUEST,
  LIST_SYS_FONTS_SUCCESS,
  LIST_SYS_FONTS_FAILURE,
  LIST_PRINT_TEMPLATE_LAYOUTS_REQUEST,
  LIST_PRINT_TEMPLATE_LAYOUTS_SUCCESS,
  LIST_PRINT_TEMPLATE_LAYOUTS_FAILURE,
  LIST_POSE_TIPS_REQUEST,
  LIST_POSE_TIPS_SUCCESS,
  LIST_POSE_TIPS_FAILURE,
  GET_APP_USER_STATS_REQUEST,
  GET_APP_USER_STATS_SUCCESS,
  GET_APP_USER_STATS_FAILURE,
  LIST_PORTFOLIO_EVENTS,
  LIST_PORTFOLIO_EVENTS_REQUEST,
  LIST_PORTFOLIO_EVENTS_SUCCESS,
  LIST_PORTFOLIO_EVENTS_FAILURE,
  LIST_PORTFOLIO_EVENTS_RESET,
  GET_PORTFOLIO_SETTINGS_REQUEST,
  GET_PORTFOLIO_SETTINGS_SUCCESS,
  GET_PORTFOLIO_SETTINGS_FAILURE,
  UPDATE_USER_PLAN_REQUEST,
  UPDATE_USER_PLAN_SUCCESS,
  UPDATE_USER_PLAN_FAILURE,
  VIRTUAL_BOOTH_REQUEST,
  VIRTUAL_BOOTH_SUCCESS,
  VIRTUAL_BOOTH_FAILURE,
  SUPERUSER_LOGIN_REQUEST,
  SUPERUSER_LOGIN_SUCCESS,
  SUPERUSER_LOGIN_FAILURE,
} from '../actions/actions';

import { marketingMaterials } from './marketing';
import { proposalData } from './proposal';
import { countries } from './countries';

import { eventMerge } from '../utils/sse';

const IMAGES = [
  { id: 'one', src: '/images/samples/people.jpg' },
  { id: 'two', src: '/images/samples/two.png' },
  { id: 'three', src: '/images/samples/three.png' },
  { id: 'four', src: '/images/samples/four.png' },
  { id: 'five', src: '/images/samples/five.png' },
  { id: 'six', src: '/images/samples/six.png' },
  { id: 'seven', src: '/images/samples/seven.png' },
  { id: 'eight', src: '/images/samples/eight.png' },
  { id: 'nine', src: '/images/samples/nine.png' },
];

const BACKGROUND = '/images/samples/background2.jpg';
const LOGO = '/images/samples/logo.png';

const initialState = {
  date: '',
  title: '',
  images: IMAGES,
  background: '',
  logo: '',
};

function initData(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_PHOTO: {
      const photoId = action.id;
      const found = IMAGES.find((image) => image.id === photoId);
      return { ...state, activePhoto: found };
    }
    default:
      return state;
  }
}

function activeEvent(state = { date: '', background: '', title: '', logo: '', eventMedias: [] }, action) {
  switch (action.type) {
    case ACTIVE_EVENT: {
      return { ...state, ...action.payload };
    }
    case RETRIEVED_GET_EVENT_ANALYTICS: {
      return { ...state, analytics: action.payload };
    }
    default: {
      return state;
    }
  }
}

function activeMedia(state = '', action) {
  switch (action.type) {
    case ACTIVE_MEDIA: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

function pageType(state = 'event', action) {
  switch (action.type) {
    case PAGE_TYPE: {
      return action.pageType;
    }
    default:
      return state;
  }
}

function isFetching(state = { count: 0 }, action) {
  switch (action.type) {
    case START_FETCH: {
      const count = state.count + 1;
      return { ...state, count };
    }

    case END_FETCH: {
      const count = state.count - 1;
      return { ...state, count };
    }
    default:
      return state;
  }
}

function events(state = {}, action) {
  switch (action.type) {
    case RETRIEVED_GET_EVENT: {
      const { eventId } = action.payload;
      return { ...state, [eventId]: action.payload };
    }
    case UPDATE_EVENT_MEDIAS: {
      const { eventId, mediaIds } = action;
      const event = state[eventId];
      if (event) {
        event.eventMedias = event.eventMedias.concat(mediaIds);
      }
      return { ...state, [eventId]: event };
    }
    case UPDATE_EVENT_HAS_MORE: {
      const { eventId, value } = action;
      const event = state[eventId];
      if (event) {
        event.hasMore = value;
      }
      return { ...state, [eventId]: event };
    }
    case UPDATE_EVENT_CLEAR_MEDIAS: {
      const { eventId } = action;
      const event = state[eventId];
      if (event) {
        event.eventMedias = [];
        event.hasMore = true;
      }
      return { ...state, [eventId]: event };
    }
    default:
      return state;
  }
}

function portfolioEvents(
  state = { loading: false, error: null, hasMore: false, searchText: null, count: 0, data: [] },
  action
) {
  switch (action.type) {
    case LIST_PORTFOLIO_EVENTS:
      return _.merge({}, state, {
        searchText: _.get(action, 'data.searchText', null),
        count: _.get(action, 'data.pagination.count', 0),
      });
    case LIST_PORTFOLIO_EVENTS_REQUEST:
      return _.merge({}, state, { loading: true, error: null });
    case LIST_PORTFOLIO_EVENTS_SUCCESS:
      return _.merge({}, state, {
        loading: false,
        hasMore: state.count > 0 && action.data.length >= state.count,
        data: _.concat(state.data, action.data),
      });
    case LIST_PORTFOLIO_EVENTS_FAILURE:
      return _.merge({}, state, { loading: false, hasMore: false, error: action.error });
    case LIST_PORTFOLIO_EVENTS_RESET:
      return { loading: false, error: null, hasMore: false, count: 0, data: [] };
    default:
      return state;
  }
}

function portfolioSettings(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case GET_PORTFOLIO_SETTINGS_REQUEST:
      return _.merge({}, state, { loading: true, error: null });
    case GET_PORTFOLIO_SETTINGS_SUCCESS:
      return _.merge({}, state, { loading: false, data: action.data });
    case GET_PORTFOLIO_SETTINGS_FAILURE:
      return _.merge({}, state, { loading: false, error: action.error });
    default:
      return state;
  }
}

function analytics(state = {}, action) {
  switch (action.type) {
    case RETRIEVED_GET_EVENT_ANALYTICS: {
      return { ...state, ...action.payload };
    }
    case RETRIEVED_GET_EVENT_TOP_MEDIAS: {
      return { ...state, mediaViewsAndShares: action.payload };
    }
    default: {
      return state;
    }
  }
}

function photos(state = {}, action) {
  switch (action.type) {
    case RETRIEVED_GET_MEDIAS: {
      return { ...state, ...action.payload };
    }
    case CLEAR_MEDIAS: {
      return {};
    }
    default:
      return state;
  }
}

function newMedias(state = {}, action) {
  switch (action.type) {
    case UPDATE_NEW_MEDIAS: {
      return action.payload;
    }
    default:
      return state;
  }
}

function filters(state = {}, action) {
  switch (action.type) {
    case SET_MEDIA_FILTERS: {
      return { ...state, ...action.payload };
    }
    case CLEAR_MEDIA_FILTERS: {
      return {};
    }
    default:
      return state;
  }
}

function templates(state = {}, action) {
  switch (action.type) {
    case RETRIEVED_GET_TEMPLATES: {
      const { payload, templateId } = action;
      const updates = {};
      const medias = [];
      payload.forEach((media) => {
        const newMedia = {
          src: media.thumbnailUrls.large,
          original: media.mediaUrls.original,
          id: media.mediaId,
        };
        if (media.type === 'video/mp4') {
          newMedia.type = 'video';
        } else {
          newMedia.type = 'image';
        }
        medias.push(newMedia);
      });
      updates[templateId] = medias;

      return { ...state, ...updates };
    }
    default:
      return state;
  }
}

function mediaNotFound(state = false, action) {
  switch (action.type) {
    case MEDIA_NOT_FOUND: {
      return action.value;
    }
    default:
      return state;
  }
}

function eventNotFound(state = false, action) {
  switch (action.type) {
    case EVENT_NOT_FOUND: {
      return action.value;
    }
    default:
      return state;
  }
}

function downloadLink(state = 'unavailable', action) {
  switch (action.type) {
    case DOWNLOAD_LINK: {
      return action.value;
    }
    default:
      return state;
  }
}

const initialSharingState = {
  loading: false,
  error: false,
  data: null,
};

function sharePhoto(state = initialSharingState, action) {
  switch (action.type) {
    case SHARE_PHOTO_REQUEST: {
      return { ...state, loading: true, error: false, success: false };
    }
    case SHARE_PHOTO_SUCCESS: {
      return { ...state, loading: false, error: false, success: true };
    }
    case SHARE_PHOTO_FAILURE: {
      return { ...state, loading: false, error: true, success: false };
    }
    default:
      return state;
  }
}

function shareImage(state = 'unavailable', action) {
  switch (action.type) {
    case SHARE_IMAGE: {
      return action.value;
    }
    default:
      return state;
  }
}

function loadingMedia(state = false, action) {
  switch (action.type) {
    case LOADING_MEDIA:
      return action.value;
    default:
      return state;
  }
}

function loadingMedias(state = false, action) {
  switch (action.type) {
    case LOADING_MEDIAS:
      return action.value;
    default:
      return state;
  }
}

function fetchPlans(state = { loading: false, error: false, success: false }, action) {
  switch (action.type) {
    case FETCH_PLANS_REQUEST:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        plans: action.plans.plans,
        features: action.plans.features,
      };
    case FETCH_PLANS_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

function currentPlan(state = {}, action) {
  switch (action.type) {
    case CURRENT_PLAN:
      return action.plan || state;
    default:
      return state;
  }
}

function fetchDevices(state = { loading: false, error: false, success: false }, action) {
  switch (action.type) {
    case FETCH_DEVICES_REQUEST:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        devices: action.data.devices,
        numLicenses: action.data.numLicenses,
      };
    case FETCH_DEVICES_FAILURE:
      return { ...state, loading: false, error: true };
    case REMOVE_DEVICE_REQUEST:
      return { ...state, loading: true, error: false, success: false };
    case REMOVE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        devices: action.data.devices,
        numLicenses: action.data.numLicenses,
      };
    case REMOVE_DEVICE_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

function currentDeviceQuantity(state = {}, action) {
  switch (action.type) {
    case CURRENT_DEVICE_QUANTITY:
      return action.profile;
    default:
      return state;
  }
}

function fetchProfile(state = { loading: false, error: false, success: false }, action) {
  switch (action.type) {
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        reRender: action.reRender,
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        profile: action.profile,
        reRender: action.reRender,
      };
    case SET_PROFILE_DATA:
      return {
        loading: false,
        error: false,
        success: true,
        reRender: true,
        profile: _.merge(state.profile, action.data),
      };
    case UPDATE_OPERATOR_SUCCESS: {
      const profile = _.assign(
        {},
        state.profile,
        _.pick(action.data, [
          'firstName',
          'lastName',
          'email',
          'phoneNumber',
          'onboarded',
          'newEmail',
          'dropbox',
          'company',
        ])
      );
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        profile,
        reRender: true,
      };
    }
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        reRender: action.reRender,
      };
    default:
      return state;
  }
}

function fetchInvoices(state = { loading: false, error: false, success: false }, action) {
  switch (action.type) {
    case FETCH_INVOICES_REQUEST:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        invoices: action.invoices,
      };
    case FETCH_INVOICES_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

function updateOrganization(state = { loading: false, error: false, success: false }, action) {
  switch (action.type) {
    case UPDATE_ORGANIZATION_REQUEST:
      return { ...state, loading: true, error: false, success: false };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        subscriptionId: action.organization.subscriptionId,
        coupon: action.organization.coupon,
      };
    case UPDATE_ORGANIZATION_FAILURE:
      return { ...state, loading: false, error: action.response.message };
    case DEFAULT_ORGANIZATION_UPDATE:
      return { ...state, loading: false, error: false, success: false };
    default:
      return state;
  }
}

function userData(
  state = { loading: false, errorLogin: false, errorSignup: false, success: false, user: null },
  action
) {
  switch (action.type) {
    case SIGN_UP_REQUEST:
    case LOG_IN_REQUEST:
      return {
        ...state,
        loading: true,
        errorLogin: false,
        errorSignup: false,
        success: false,
        user: null,
      };
    case SIGN_UP_SUCCESS:
    case LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        errorLogin: false,
        errorSignup: false,
        success: true,
        user: action.user,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
        errorLogin: false,
        errorSignup: action.error,
        success: false,
        user: null,
      };
    case LOG_IN_FAILURE:
      return {
        ...state,
        loading: false,
        errorLogin: true,
        errorSignup: false,
        success: false,
        user: null,
      };
    case LOG_IN_INCORRECT:
      return {
        ...state,
        loading: false,
        errorLogin: action.error,
        errorSignup: false,
        success: false,
        user: null,
        incorrectLogin: true,
      };
    default:
      return state;
  }
}

function resetPasswordInitiateData(
  state = { loading: false, error: false, success: false, email: null, isEmailInvalid: null },
  action
) {
  switch (action.type) {
    case RESET_PASSWORD_INITIATE_REQUEST:
      return { ...state, loading: true, error: false, success: false, email: null };
    case RESET_PASSWORD_INITIATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        email: action.email,
        isEmailInvalid: action.isEmailInvalid,
      };
    case RESET_PASSWORD_INITIATE_FAILURE:
      return { ...state, loading: false, error: true, success: false, email: action.email };
    default:
      return state;
  }
}

function resetPasswordValidateData(
  state = { loading: false, error: false, success: false, token: null, isTokenInvalid: null, isTokenExpired: null },
  action
) {
  switch (action.type) {
    case RESET_PASSWORD_VALIDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        token: action.token,
        isTokenInvalid: null,
        isTokenExpired: null,
      };
    case RESET_PASSWORD_VALIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        token: action.token,
        isTokenInvalid: action.isTokenInvalid,
        isTokenExpired: action.isTokenExpired,
      };
    case RESET_PASSWORD_VALIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        token: action.token,
        isTokenInvalid: null,
        isTokenExpired: null,
      };
    default:
      return state;
  }
}

function resetPasswordFinallyData(state = { loading: false, error: false, success: false }, action) {
  switch (action.type) {
    case RESET_PASSWORD_FINALLY_REQUEST:
      return { ...state, loading: true, error: false, success: false };
    case RESET_PASSWORD_FINALLY_SUCCESS:
      return { ...state, loading: false, error: false, success: true };
    case RESET_PASSWORD_FINALLY_FAILURE:
      return { ...state, loading: false, error: true, success: false };
    default:
      return state;
  }
}

function accountLoading(state = { loading: 0 }, action) {
  switch (action.type) {
    case ACCOUNT_FETCH_REQUEST: {
      const count = state.loading + 1;
      return { ...state, loading: count };
    }

    case ACCOUNT_FETCH_FINISHED: {
      const count = state.loading - 1;
      return { ...state, loading: count };
    }
    default:
      return state;
  }
}

function checkoutData(state = { cost: undefined, selectedPlan: undefined, quantity: undefined }, action) {
  switch (action.type) {
    case STORE_CHECKOUT_DATA_REQUEST:
      return {
        ...state,
        cost: action.data.cost,
        selectedPlan: action.data.selectedPlan,
        quantity: action.data.quantity,
        planChangeType: action.data.planChangeType,
        isCurrentPlan: action.data.isCurrentPlan,
      };
    default:
      return state;
  }
}

function pricing(state = { loading: false, error: false, cost: undefined }, action) {
  switch (action.type) {
    case PRICING_REQUEST:
      return {
        loading: true,
        error: false,
        success: false,
        cost: undefined,
      };
    case PRICING_FAILURE:
      return {
        loading: false,
        error: true,
        success: false,
        cost: undefined,
      };
    case PRICING_SUCCESS:
      return {
        loading: false,
        error: false,
        success: true,
        cost: action.data.data,
      };
    default:
      return state;
  }
}

function requiredPassword(state = { requested: false }, action) {
  switch (action.type) {
    case EVENT_PASSWORD_REQUEST:
      return {
        ...state,
        requested: true,
      };
    case PASSWORD_NOT_REQUIRED:
      return {
        ...state,
        requested: false,
      };
    default:
      return state;
  }
}

function passwordToken(state = { loading: false, token: null, hint: null }, action) {
  switch (action.type) {
    case PASSWORD_TOKEN_REQUEST:
      return {
        loading: true,
        token: null,
        hint: null,
        error: null,
      };
    case PASSWORD_TOKEN_SUCCESS:
      return {
        loading: false,
        token: action.data.token,
        hint: null,
        error: null,
      };
    case PASSWORD_TOKEN_FAILURE: {
      const hint = action.data && action.data.hint;
      const error = action.data && action.data.error;
      return {
        loading: false,
        token: null,
        hint,
        error,
      };
    }
    default:
      return state;
  }
}

function loadingEvent(state = { loading: false }, action) {
  switch (action.type) {
    case LOADING_EVENT:
      return {
        loading: true,
      };
    case FINISHED_EVENT:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

function environment(state = '', action) {
  switch (action.type) {
    case UPDATE_ENVIRONMENT:
      return action.environment;
    default:
      return state;
  }
}

function operator(state = '', action) {
  switch (action.type) {
    case SET_OPERATOR:
      return action.operator;
    default:
      return state;
  }
}

function appEvents(
  state = { loading: false, error: null, hasMore: false, searchText: null, count: 0, data: [] },
  action
) {
  switch (action.type) {
    case LIST_EVENTS:
      return _.merge({}, state, {
        searchText: _.get(action, 'data.searchText', null),
        count: _.get(action, 'data.pagination.count', 0),
      });
    case LIST_EVENTS_REQUEST:
      return _.merge({}, state, { loading: true, error: null });
    case LIST_EVENTS_SUCCESS:
      return _.merge({}, state, {
        loading: false,
        hasMore: state.count > 0 && action.data.length >= state.count,
        data: _.concat(state.data, action.data),
      });
    case LIST_EVENTS_FAILURE:
      return _.merge({}, state, { loading: false, hasMore: false, error: action.error });
    case LIST_EVENTS_RESET:
      return { loading: false, error: null, hasMore: false, count: 0, data: [] };
    default:
      return state;
  }
}

function appSampleEvents(state = { loading: false, error: null, data: [] }, action) {
  switch (action.type) {
    case LIST_SAMPLE_EVENTS_REQUEST:
      return _.merge({}, state, { loading: true, error: null });
    case LIST_SAMPLE_EVENTS_SUCCESS:
      return _.merge({}, state, { loading: false, data: action.data });
    case LIST_SAMPLE_EVENTS_FAILURE:
      return _.merge({}, state, { loading: false, error: action.error });
    default:
      return state;
  }
}

function userAssets(
  state = { loading: false, uploading: false, success: null, error: null, hasMore: false, count: 0, data: [] },
  action
) {
  switch (action.type) {
    case LIST_ASSETS:
      return _.merge({}, state, { count: _.get(action, 'data.pagination.count', 0) });
    case LIST_ASSETS_REQUEST:
      return _.merge({}, state, { loading: true, success: null, error: null });
    case LIST_ASSETS_SUCCESS:
      return _.merge({}, state, {
        loading: false,
        hasMore: state.count > 0 && action.data.length >= state.count,
        data: _.concat(state.data, action.data),
      });
    case LIST_ASSETS_FAILURE:
      return _.merge({}, state, { loading: false, hasMore: false, error: action.error });
    case LIST_ASSETS_RESET:
      return { loading: false, success: null, error: null, hasMore: false, count: 0, data: [] };
    case UPLOAD_ASSET_REQUEST:
      return _.merge({}, state, { uploading: true, error: null });
    case UPLOAD_ASSET_SUCCESS:
      return _.merge({}, state, { uploading: false, success: true, error: null });
    case UPLOAD_ASSET_FAILURE:
      return _.merge({}, state, { uploading: false, success: false, error: action.error });
    case DELETE_ASSETS_REQUEST:
      return _.merge({}, state, { loading: true, success: null, error: null });
    case DELETE_ASSETS_SUCCESS:
      return { ...state, loading: false, success: true, data: state.data.filter((a) => !action.data.includes(a.id)) };
    case DELETE_ASSETS_FAILURE:
      return _.merge({}, state, { loading: false, success: false, error: action.error });
    default:
      return state;
  }
}

function sysAssets(state = { loading: false, success: null, error: null, data: [] }, action) {
  switch (action.type) {
    case LOAD_SYS_ASSETS_REQUEST:
      return _.merge({}, state, { loading: true, success: null, error: null });
    case LOAD_SYS_ASSETS_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case LOAD_SYS_ASSETS_FAILURE:
      return _.merge({}, state, { loading: false, error: action.error });
    default:
      return state;
  }
}

function appEventMedias(state = { loading: false, error: null, hasMore: false, count: 0, data: [] }, action) {
  switch (action.type) {
    case LIST_MEDIAS:
      return _.merge({}, state, { count: _.get(action, 'data.pagination.count', 0) });
    case LIST_MEDIAS_REQUEST:
      return _.merge({}, state, { loading: true, error: null });
    case LIST_MEDIAS_SUCCESS:
      return _.merge({}, state, {
        loading: false,
        hasMore: state.count > 0 && action.data.length >= state.count,
        data: _.concat(state.data, action.data),
      });
    case LIST_MEDIAS_FAILURE:
      return _.merge({}, state, { loading: false, hasMore: false, error: action.error });
    case LIST_MEDIAS_RESET:
      return { loading: false, error: null, hasMore: true, count: 0, data: [] };
    default:
      return state;
  }
}

function appCreateEvent(state = { loading: false, error: null }, action) {
  switch (action.type) {
    case CREATE_EVENT_REQUEST:
      return { loading: true, error: null };
    case CREATE_EVENT_SUCCESS:
      return { loading: false, error: null };
    case CREATE_EVENT_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

function appDuplicateEvent(state = { loading: false, error: null }, action) {
  switch (action.type) {
    case DUPLICATE_EVENT_REQUEST:
      return { loading: true, error: null };
    case DUPLICATE_EVENT_SUCCESS:
      return { loading: false, error: null };
    case DUPLICATE_EVENT_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

function appEvent(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case GET_EVENT_REQUEST:
      return { loading: true, error: null, data: {} };
    case GET_EVENT_SUCCESS:
      return { loading: false, error: null, data: action.data };
    case UPDATE_EVENT_SUCCESS:
      return { loading: false, error: null, data: action.data };
    case SET_EVENT_DATA:
      return { loading: false, error: null, data: eventMerge(state.data, action.data) };
    case GET_EVENT_FAILURE:
      return { loading: false, error: action.error, data: {} };
    default:
      return state;
  }
}

function printTemplates(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case GET_PRINT_TEMPLATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PRINT_TEMPLATE_SUCCESS:
    case CREATE_PRINT_TEMPLATE_SUCCESS:
    case UPDATE_PRINT_TEMPLATE_SUCCESS: {
      const { id } = action.data;
      return {
        loading: false,
        error: null,
        data: { ...state.data, [id]: action.data },
      };
    }
    case GET_PRINT_TEMPLATE_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CREATE_PRINT_TEMPLATE_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_PRINT_TEMPLATE_FAILURE:
      return { ...state, loading: false, error: action.error };
    case UPDATE_PRINT_TEMPLATE_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_PRINT_TEMPLATE_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

function printTemplateList(state = { loading: false, error: null, data: [], hasMore: true }, action) {
  switch (action.type) {
    case LIST_PRINT_TEMPLATES_REQUEST:
      return { ...state, loading: true, error: null };
    case LIST_PRINT_TEMPLATES_SUCCESS:
      return { ...state, loading: false, data: [...state.data, ...action.data], hasMore: !!action.data.length };
    case LIST_PRINT_TEMPLATES_FAILURE:
      return { ...state, loading: false, error: action.error };
    case LIST_PRINT_TEMPLATES_RESET:
      return { loading: false, error: null, data: [], hasMore: true };
    case DELETE_PRINT_TEMPLATES_SUCCESS:
      return { loading: false, error: null, data: state.data.filter((t) => !action.ids.includes(t.id)), hasMore: true };
    default:
      return state;
  }
}

function sysTemplatesList(state = { loading: false, error: null, data: [] }, action) {
  switch (action.type) {
    case LIST_SYS_TEMPLATES_REQUEST:
      return { ...state, loading: true, error: null };
    case LIST_SYS_TEMPLATES_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case LIST_SYS_TEMPLATES_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

function sysFontsList(state = { loading: false, error: null, data: [] }, action) {
  switch (action.type) {
    case LIST_SYS_FONTS_REQUEST:
      return { ...state, loading: true, error: null };
    case LIST_SYS_FONTS_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case LIST_SYS_FONTS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

function printTemplateLayouts(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case LIST_PRINT_TEMPLATE_LAYOUTS_REQUEST:
      return { ...state, loading: true, error: null };
    case LIST_PRINT_TEMPLATE_LAYOUTS_SUCCESS:
      return { ...state, data: action.data, loading: false, error: null };
    case LIST_PRINT_TEMPLATE_LAYOUTS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

function poseTips(state = { loading: false, error: null, data: [] }, action) {
  switch (action.type) {
    case LIST_POSE_TIPS_REQUEST:
      return { ...state, loading: true, error: null };
    case LIST_POSE_TIPS_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case LIST_POSE_TIPS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

function appEventView(state = { tab: '', subtab: '' }, action) {
  switch (action.type) {
    case SET_EVENT_VIEW:
      return _.isEmpty(action.data) ? { tab: '', subtab: '' } : _.assign({}, state, action.data);
    default:
      return state;
  }
}

function sseEvent(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case GET_SSE_EVENT_REQUEST:
      return { loading: true, error: null, data: {} };
    case GET_SSE_EVENT_SUCCESS:
      return { loading: false, error: null, data: action.data };
    case GET_SSE_EVENT_FAILURE:
      return { loading: false, error: action.error, data: {} };
    default:
      return state;
  }
}

function appProfileView(state = { tab: '', subtab: '' }, action) {
  switch (action.type) {
    case SET_PROFILE_VIEW:
      return _.isEmpty(action.data) ? { tab: '', subtab: '' } : _.assign({}, state, action.data);
    default:
      return state;
  }
}

function sseProfile(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case GET_SSE_PROFILE_REQUEST:
      return { loading: true, error: null, data: {} };
    case GET_SSE_PROFILE_SUCCESS:
      return { loading: false, error: null, data: action.data };
    case GET_SSE_PROFILE_FAILURE:
      return { loading: false, error: action.error, data: {} };
    default:
      return state;
  }
}

function appUserStats(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case GET_APP_USER_STATS_REQUEST:
      return { loading: true, error: null, data: {} };
    case GET_APP_USER_STATS_SUCCESS:
      return { loading: false, error: null, data: action.data };
    case GET_APP_USER_STATS_FAILURE:
      return { loading: false, error: action.error, data: {} };
    default:
      return state;
  }
}

function updateUserPlan(state = { loading: false, error: null, data: {} }, action) {
  switch (action.type) {
    case UPDATE_USER_PLAN_REQUEST:
      return { loading: true, error: null, data: {} };
    case UPDATE_USER_PLAN_SUCCESS:
      return { loading: false, error: null, data: action.data };
    case UPDATE_USER_PLAN_FAILURE:
      return { loading: false, error: action.error, data: {} };
    default:
      return state;
  }
}

function superUserData(state = { loading: false, error: null, user: null }, action) {
  switch (action.type) {
    case SUPERUSER_LOGIN_REQUEST:
      return { loading: true, error: null, user: null };
    case SUPERUSER_LOGIN_SUCCESS:
      return { loading: false, error: null, user: action.data };
    case SUPERUSER_LOGIN_FAILURE:
      return { loading: false, error: action.error, user: null };
    default:
      return state;
  }
}

export const rootReducer = {
  passwordToken,
  requiredPassword,
  checkoutData,
  accountLoading,
  userData,
  resetPasswordInitiateData,
  resetPasswordValidateData,
  resetPasswordFinallyData,
  initData,
  pageType,
  isFetching,
  loadingEvent,
  activeEvent,
  activeMedia,
  events,
  portfolioEvents,
  portfolioSettings,
  analytics,
  photos,
  newMedias,
  templates,
  mediaNotFound,
  eventNotFound,
  downloadLink,
  shareImage,
  sharePhoto,
  loadingMedia,
  loadingMedias,
  fetchPlans,
  fetchDevices,
  fetchProfile,
  fetchInvoices,
  updateOrganization,
  currentPlan,
  pricing,
  filters,
  environment,
  operator,
  appEvents,
  appSampleEvents,
  appCreateEvent,
  appDuplicateEvent,
  appEvent,
  appEventMedias,
  appEventView,
  sseEvent,
  appProfileView,
  sseProfile,
  userAssets,
  sysAssets,
  printTemplates,
  printTemplateList,
  sysTemplatesList,
  sysFontsList,
  printTemplateLayouts,
  poseTips,
  appUserStats,
  updateUserPlan,
  marketingMaterials,
  proposalData,
  superUserData,
  countries,
};

export default combineReducers(rootReducer);
